import React from 'react';
import {baseUrl} from "../../../../../../../constants/constants";
import {IOneEmployee} from "../EmployeeCard";
import '../../../../aboutUs.scss'
import './EmployeeAboutForm.scss'

const EmployeeAboutForm = ({fio, image_url, position_departament_name, interests, achievement}: IOneEmployee) => {

    return (
        <div className='employee-about'>
            <img
                src={`${baseUrl}${image_url}`}
                alt="Фотография сотрудника"
                className='employee-modal__img'
            />
            <section className='employee-modal__description'>
            <div className='employee-about__text-block'>
                <h2 className='employee-modal__fio'>{fio}</h2>
                <p>{position_departament_name}</p>
            </div>
            <div className='employee-about__text-block'>
                <h2 className='employee-modal__title'>Интересы: </h2>
                <p className='employee-modal__text'>{interests}</p>
            </div>
            <div className='employee-about__text-block'>
                <h2 className='employee-modal__title'>Достижения: </h2>
                <p className='employee-modal__text'>{achievement}</p>
            </div>
            </section>
        </div>
    );
};

export default EmployeeAboutForm;