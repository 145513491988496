import React, {useState} from 'react';
import {IJournalsSettings} from "../../JournalsSettingsPage";
import {baseUrl} from "../../../../../../constants/constants";
import Modal from "../../../../../../components/modal/modal";
import UpdateJournal from "../UpdateJournal/UpdateJournal";

interface IJournalsList{
    journalsList: IJournalsSettings;
    deleteJournal: (currentJournal: IJournalsSettings) => void
}

const JournalsList:React.FC<IJournalsList> = ({journalsList, deleteJournal}) => {
    const [showUpdateJournalModal, setShowUpdateJournalModal] = useState(false)

    const closeModal = () => {
        setShowUpdateJournalModal(false)
    }

    const date_publication = new Date(journalsList.date_publication).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    })

    return (
        <tr>
            <td className="applications-table__tbody-td">
                <img
                src={`${baseUrl}${journalsList.file_card_url}`}
                alt="Обложка журнала"
                className="cards-settings__img"
            />
            </td>
            <td className="applications-table__tbody-td">{journalsList.name}</td>
            <td className="applications-table__tbody-td">
                <a
                    href={`${baseUrl}${journalsList.file_url}`}
                    style={{textDecoration: 'underline', color: 'blue'}}
                    target='_blank'
                >
                    {journalsList.file_url}
                </a>
            </td>
            <td className="applications-table__tbody-td">{date_publication}</td>
            <td className="applications-table__tbody-td">
                <button
                    className="button updateBtn"
                    onClick={() => setShowUpdateJournalModal(true)}
                >
                    Обновить
                </button>
                <button
                    className="button updateBtn"
                    onClick={() => deleteJournal(journalsList)}
                >
                    Удалить
                </button>
            </td>
            {showUpdateJournalModal &&
                <Modal
                    active={showUpdateJournalModal}
                    title='Обновите информацию о журнале'
                        сlose={closeModal}
                    >
                        <UpdateJournal
                            journal={journalsList}
                            close={closeModal}
                        />
                    </Modal>
                }
        </tr>
    );
};

export default JournalsList;