import React, {useEffect, useState} from 'react';
import CreateNewManual from "./CreateNewManual/CreateNewManual";
import ManualsList from "./ManualsList/ManualsList";
import {baseUrl} from "../../../../../../constants/constants";
import Modal from "../../../../../../components/modal/modal";
import CardsList from "../Cards/CardsList/CardsList";
import {Link} from "react-router-dom";
import {successToast} from "../../../../../../shared/toastify";

export interface IManualsSettings{
    id: number,
    name: string,
    file_url: string,
    file_card_url: string,
    create_date: Date,
    category_id: number
}

export interface ICategoryManuals{
    id: number,
    name: string
}

const ManualsSettings = () => {
    const [manuals, setManuals] = useState<IManualsSettings[]>([]);
    const [categoryManuals, setCategoryManuals] = useState<ICategoryManuals[]>([]);
    const [showManualModal, setShowManualModal] = useState(false);

    useEffect(() => {
        fetch(`${baseUrl}manuals?page_size=100&sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setManuals(data)
            })
            .catch((err) => {
                console.log(err)
            })

        fetch(`${baseUrl}category?type_category=manual`)
            .then((res) => res.json())
            .then((data) => {
                setCategoryManuals(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    async function deleteCurrentManual(id: number){
        try{
            const res = await fetch(`${baseUrl}manuals/${id}`, {
                method: 'DELETE'
            });
            successToast('Методичка удалена!')
            return res.ok;
        } catch (err){
            console.log(err)
        }
    }

    const deleteManual = (id: number) => {
        setManuals(manuals.filter((manuals) => manuals.id !== id));
    };

    const updateManualStatus = (currentManual: IManualsSettings) => {
        deleteCurrentManual(currentManual.id);
        setManuals([...manuals, { ...currentManual}]);
        deleteManual(currentManual.id);
    };

    const closeModal = () => {
        setShowManualModal(false)
    }

    return (
        <>
            <section className="admin-section">
                <h1 className="admin-title__h1-media">Редактирование секции "Медиаматериалы"</h1>
                <section className="admin-media__btns-section">
                    <Link to='/admin-panel/media/video'>
                        <button className="button admin-media__btn">Видео</button>
                    </Link>
                    <Link to='#'>
                        <button className="button admin-media__btn-active">Методички</button>
                    </Link>
                    <Link to='/admin-panel/media/cards'>
                        <button className="button admin-media__btn">Карточки</button>
                    </Link>
                </section>
                <div className="admin-buttons">
                    <p className="admin-title__p">Добавить методичку: </p>
                    <button
                        className="button admin-btn"
                        onClick={() => setShowManualModal(true)}
                    >
                        Добавить
                    </button>
                    {showManualModal &&
                        <Modal
                            active={showManualModal}
                            title='Добавьте информацию о методичке'
                            сlose={closeModal}
                        >
                            <CreateNewManual
                                close={closeModal}
                                category={categoryManuals}
                            />
                        </Modal>
                    }
                </div>
                <div className="one-table">
                    <table className="applications-table">
                        <thead className='applications-table__thead'>
                        <tr>
                            <th className="applications-table__thead-th">Картинка</th>
                            <th className="applications-table__thead-th">Название</th>
                            <th className="applications-table__thead-th">Категория</th>
                            <th className="applications-table__thead-th">Файл</th>
                            <th className="applications-table__thead-th">Действия</th>
                        </tr>
                        </thead>
                        {manuals.map((i) => (
                            <tbody
                                className="applications-table__tbody"
                                key={i.id}
                            >
                            <ManualsList
                                manualsList={i}
                                category={categoryManuals}
                                deleteManual={updateManualStatus}
                            />
                            </tbody>
                        ))}
                    </table>
                </div>
            </section>
        </>
    );
};

export default ManualsSettings;