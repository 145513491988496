import {baseUrl} from "../../constants/constants";

export function dwnldCourse(){
    fetch(`${baseUrl}statistic/blank-online/export`, {
        method: 'GET',
        headers:{
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'Онлайн курсы.xlsx',
            )
            document.body.appendChild(link)
            link.click()
            link.parentNode?.removeChild(link)
        })
}

export function dwnldForums(){
    fetch(`${baseUrl}statistic/blank-forum/export`, {
        method: 'GET',
        headers:{
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'Мероприятия.xlsx',
            )
            document.body.appendChild(link)
            link.click()
            link.parentNode?.removeChild(link)
        })
}

export function dwnldWriter(){
    fetch(`${baseUrl}statistic/blank-writer/export`, {
        method: 'GET',
        headers:{
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'Обзор.НЦПТИ.xlsx',
            )
            document.body.appendChild(link)
            link.click()
            link.parentNode?.removeChild(link)
        })
}

export function dwnldOfflineCourse(){
    fetch(`${baseUrl}statistic/blank-offline/export`, {
        method: 'GET',
        headers:{
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'Оффлайн курсы.xlsx',
            )
            document.body.appendChild(link)
            link.click()
            link.parentNode?.removeChild(link)
        })
}

export function dwnldUser(){
    fetch(`${baseUrl}statistic/user/export`, {
        method: 'GET',
        headers:{
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(
                new Blob([blob])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'Список пользователей.xlsx',
            )
            document.body.appendChild(link)
            link.click()
            link.parentNode?.removeChild(link)
        })
}