import React from 'react';
import {IJournalsSettings} from "../../JournalsSettingsPage";
import {SubmitHandler, useForm} from "react-hook-form";
import {getYearMothDayFormat} from "../../../ForumsSettingsPage/components/UpdateForum/utils";
import {Form} from "react-router-dom";
import {baseUrl} from "../../../../../../constants/constants";
import journalsList from "../JournalsList/JournalsList";
import {ftruncate} from "node:fs";
import {errorToast, successToast} from "../../../../../../shared/toastify";

interface INewInfoJournal{
    name: string,
    file: string,
    file_card: string,
    date_publication: string
}

interface ICurrentJournal{
    journal: IJournalsSettings,
    close: () => void
}

const UpdateJournal:React.FC<ICurrentJournal> = ({journal, close}) => {
    const{
        register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<INewInfoJournal>({
        defaultValues:{
            name: journal.name,
            date_publication: getYearMothDayFormat(
                new Date(journal.date_publication)
            ),
        }
    })

    const submit: SubmitHandler<INewInfoJournal> = async(data: any)=> {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'file' || key == 'file_card'){
                    if(!!data[key][0]){
                        formData.append(key, data[key][0])
                    }
                } else {formData.append(key, data[key])}
            }
            const res = await fetch(`${baseUrl}journals/${journal.id}`,{
                method: 'PUT',
                body: formData
            })
            if (res.status === 200){
                successToast('Обновлена информация о методичке! Пожалуйста, обновите страницу');
                close()
            } else if(res.status === 401){
                setError('root', {type: 'custom', message:''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <input
                type="text"
                className="regInput"
                placeholder='Название журнала'
                {...register('name')}
            />

            <span className="regInput__file">
                <p style={{textAlign:'start'}}>Файл журнала</p>
                <input
                    type="file"
                    {...register('file')}
                    accept='application/pdf'
                />
            </span>

            <span className="regInput__file">
                <p style={{textAlign:'start'}}>Изображение журнала</p>
                <input
                    type="file"
                    {...register('file_card')}
                    accept='image/jpg, image/jpeg, image/webp, image/png'
                />

                <label className='regLabel' style={{textAlign:'start'}}>
                    Дата публикации
                    <input
                        type="date"
                        className="regInput"
                        {...register('date_publication')}
                    />
                </label>

                <button className="button form-btn">Добавить</button>
            </span>
        </form>
    );
};

export default UpdateJournal;