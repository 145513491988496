import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../constants/constants";
import ImageGallery from 'react-image-gallery'
import './Gallery.scss'

export interface GalleryInterface{
    id: number,
    name: string,
    file_url: string,
    create_date: Date,
    category_id: number,
    category_name: string
}

const Gallery = () => {
    const[galleryImages, setGalleryImages] = useState<GalleryInterface[]>([])


    useEffect(() => {
        fetch(`${baseUrl}gallery_images?page_size=20&sort_date=true&category_id=15`)
            .then((res)=> res.json())
            .then((data) => {
                setGalleryImages(data);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    const gallery_arr = (arr:any) => arr.map((item:any) => {
        return {
            original: `${baseUrl}${item.file_url}`,
            thumbnail: `${baseUrl}${item.file_url}`
        }
    })

    useEffect(() => {

    }, []);

        return (
        <>
            {galleryImages &&
                <ImageGallery
                    items={gallery_arr(galleryImages)}
                />
            }
        </>
    );
};

export default Gallery;