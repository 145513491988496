import React, {useEffect, useState} from 'react';
import './UsersSettingsPage.scss'
import UsersList from "./components/UsersList/UsersList";
import {baseUrl} from "../../../../constants/constants";
import BannedUsersList from "./components/BannedUsersList/BannedUsersList";
import {dwnldUser, dwnldWriter} from "../../../../components/excel-export/excel-export";
import {successToast} from "../../../../shared/toastify";

export interface IUsersSettings {
    id: number,
    full_name: string,
    full_name_declination: string,
    male: boolean,
    age: number,
    place_of_work: string,
    position_work: string,
    number_phone: string,
    telegram_link: string,
    about_yourself: string,
    email: string,
    date_joined: Date,
    is_admin: boolean,
    is_active: boolean,
    email_subscription: boolean
}

const UsersSettingPage = () => {
    const [users, setUsers] = useState<IUsersSettings[]>([])
    const [bannedUsers, setBannedUsers] = useState<IUsersSettings[]>([])

    useEffect(() => {
        fetch(`${baseUrl}user`)
            .then((res) => res.json())
            .then((data: IUsersSettings[]) => {
                const filteredData = data.filter((i) => i.is_active)
                setUsers(filteredData)
            })

        fetch(`${baseUrl}user`)
            .then((res) => res.json())
            .then((data: IUsersSettings[]) => {
                const bannedData = data.filter((i) => !i.is_active)
                setBannedUsers(bannedData)
            })
    }, []);

    async function deleteCurrentUser(id: number) {
        try{
            const res = await fetch(`${baseUrl}user/${id}`, {
                method: 'DELETE'
            })
            successToast("Пользователь забанен")
            return res.ok;
        } catch (err){
            console.log(err)
        }
    }

    const deleteUser = (id: number)=> {
        setUsers(users.filter((users) => users.id !== id))
    }
    const deleteBannedUser = (id: number)=> {
        setUsers(users.filter((users) => users.id !== id))
    }

    const updateUserStatus = (currentUser: IUsersSettings) => {
        deleteCurrentUser(currentUser.id);

        if(!currentUser.is_active){
            setBannedUsers([...bannedUsers, {...currentUser, is_active: true}])
            deleteBannedUser(currentUser.id)
        } else {
            setUsers([...users, {...currentUser, is_active: false}])
            deleteUser(currentUser.id)
        }
    }

    return (
        <section className="admin-section">
            <h1 className="admin-title__h1">Редактирование секции "Пользователи"</h1>
            <div className="one-table">
                <div className="admin-buttons">
                    <h3 className='admin-title__h3'>Список активных пользователей</h3>
                    <button className="button admin-btn" onClick={dwnldUser}>Excel</button>
                </div>
                <table className="applications-table">
                    <thead className="applications-table__thead">
                    <tr>
                        {/*<th className="applications-table__thead-th">Активный?</th>*/}
                        <th className="applications-table__thead-th">Имя</th>
                        <th className="applications-table__thead-th">Пол</th>
                        <th className="applications-table__thead-th">Возраст</th>
                        <th className="applications-table__thead-th">Место работы</th>
                        <th className="applications-table__thead-th">Должность</th>
                        <th className="applications-table__thead-th">Телефон</th>
                        <th className="applications-table__thead-th">Телеграм</th>
                        <th className="applications-table__thead-th">О себе</th>
                        <th className="applications-table__thead-th">Email</th>
                        <th className="applications-table__thead-th">Подписка на Обзор</th>
                        <th className="applications-table__thead-th">Админ?</th>
                        <th className="applications-table__thead-th">Дата регистрации</th>
                        <th className="applications-table__thead-th">Действия</th>
                    </tr>
                    </thead>
                    {users.map((i) =>
                        <tbody
                            className="applications-table__tbody"
                            key={i.id}
                        >
                        <UsersList
                            usersList={i}
                            deleteUser={updateUserStatus}
                        />
                        </tbody>
                    )}
                </table>
            </div>
        </section>
    );
};

export default UsersSettingPage;