import React, {useEffect, useState} from 'react';
import './mediaMaterials.scss'
import Video from '../../../assets/materails_cards/video_.webp'
import Manuals from "../../../assets/materails_cards/manuals_.webp"
import Memos  from "../../../assets/materails_cards/memos_.webp"
import VideoMobile from '../../../assets/materails_cards/video-mobile_.webp'
import ManualsMobile from '../../../assets/materails_cards/manuals-mobile_.webp'
import MemosMobile from '../../../assets/materails_cards/memos-mobile_.webp'
import {Link} from "react-router-dom";
import {baseUrl} from "../../../constants/constants";

interface IBanner{
    id: number;
    name: string,
    file_url: string
}

const MediaMaterials = () => {
    const [banner, setBanner] = useState<IBanner[]>([])
    const [mobileBanner, setMobileBanner] = useState<IBanner[]>([])

    useEffect(() => {
        fetch(`${baseUrl}gallery_images?category_id=19`)
            .then((res) => res.json())
            .then((data: IBanner[]) => {
                const banner = data.filter((item) => item.id === 72)
                setBanner(banner)
                console.log(banner)
            })

        fetch(`${baseUrl}gallery_images?category_id=19`)
            .then((res) => res.json())
            .then((data: IBanner[]) => {
                const bannerMobile = data.filter((el) => el.id === 81)
                setMobileBanner(bannerMobile)
            })
    }, []);

    return (
        <div className='page-materials'>
            {banner && banner.map((i) => (
                <section className='title-image'>
                    <img
                        src={`${baseUrl}${i.file_url}`}
                        alt="Баннер Наши проекты"
                        loading='lazy'
                        className='title-image__img'
                    />
                </section>
            ))
            }

            {mobileBanner && mobileBanner.map((e) => (
                <section className='title-image__mobile'>
                    <img
                        src={`${baseUrl}${e.file_url}`}
                        alt="Баннер Наши проекты мобильный"
                        loading='lazy'
                        className='title-image__img-mobile'
                    />
                </section>
            ))}

            <div className="page">

                {/*Компьютерная версия*/}
                <section className="materials-cards">
                    <Link to='videomaterialy'>
                        <img src={Video} alt="Карточка Видеоматериалы" loading='lazy'
                             className='materials-cards__img'/>
                    </Link>
                    <Link to='metodichki'>
                        <img src={Manuals} alt="Карточка Методические рекомендации" loading='lazy'
                             className='materials-cards__img'/>
                    </Link>
                    <Link to='pamyatki-i-kartochki'>
                        <img src={Memos} alt="Карточка Памятки и карточки" loading='lazy'
                             className='materials-cards__img'/>
                    </Link>
                </section>

                {/*Мобильная версия*/}
                <section className="materials-cards__mobile">
                    <Link to='videomaterialy'>
                        <img src={VideoMobile} alt="Карточка Видеоматериалы" loading='lazy'
                             className='materials-cards__mobile-img'/>
                    </Link>
                    <Link to='metodichki'>
                        <img src={ManualsMobile} alt="Карточка Методические рекомендации" loading='lazy'
                             className='materials-cards__mobile-img'/>
                    </Link>
                    <Link to='pamyatki-i-kartochki'>
                        <img src={MemosMobile} alt="Карточка Памятки и карточки" loading='lazy'
                             className='materials-cards__mobile-img'/>
                    </Link>
                </section>
            </div>
        </div>
    );
};

export default MediaMaterials;