import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../constants/constants";
import Modal from "../../../../components/modal/modal";
import CreateJournal from "./components/CreateJournal/CreateJournal";
import JournalsList from "./components/JournalsList/JournalsList";
import NewsArchive from "../../components/NewsArchive/NewsArchive";
import {successToast} from "../../../../shared/toastify";

export interface IJournalsSettings{
    id: number,
    name: string,
    file_url: string,
    file_card_url: string,
    type_file: string,
    file_extension: string,
    date_publication: string
}

const JournalsSettingsPage = () => {
    const [showJournalModal, setShowJournalModal] = useState(false)
    const [journals, setJournals] = useState<IJournalsSettings[]>([])

    useEffect(() => {
        fetch(`${baseUrl}journals?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setJournals(data)
            })
    }, []);

    async function deleteCurrentJournal(id: number) {
        try{
            const res = await fetch(`${baseUrl}journals/${id}`, {
                method: 'DELETE'
            })
            successToast("Журнал удалён")
            return res.ok
        } catch (err){
            console.log(err)
        }
    }

    const deleteJournal = (id: number) => {
        setJournals(journals.filter((journals) => journals.id !== id))
    }

    const updateJournalStatus = (currentJournal: IJournalsSettings) => {
        deleteCurrentJournal(currentJournal.id)
        setJournals([...journals, {...currentJournal}])
        deleteJournal(currentJournal.id)
    }

    const closeModal = () => {
        setShowJournalModal(false)
    }


    return (
        <section className='admin-section'>
            <h1 className="admin-title__h1">Редактирование секции "Обзор.НЦПТИ"</h1>
            <div className="admin-buttons">
                <p className="admin-title__p">Добавить журнал: </p>
                <button
                    className="button admin-btn"
                    onClick={() => setShowJournalModal(true)}
                >
                    Добавить
                </button>
                {showJournalModal &&
                    <Modal
                        active={showJournalModal}
                        title='Добавьте информацию о журнале'
                        сlose={closeModal}
                    >
                        <CreateJournal close={closeModal}/>
                    </Modal>
                }
            </div>

            {/*Список журналов*/}
            <h2 className="admin-title__h2">Список журналов: </h2>
            <div className="one-table">
                <table className="applications-table">
                    <thead className="applications-table__thead">
                    <tr>
                        <th className="applications-table__thead-th">Картинка</th>
                        <th className="applications-table__thead-th">Название</th>
                        <th className="applications-table__thead-th">pdf-файл журнала</th>
                        <th className="applications-table__thead-th">Дата публикации</th>
                        <th className="applications-table__thead-th">Действия</th>
                    </tr>
                    </thead>
                    {journals.map((i) =>
                        <tbody
                            className='applications-table__tbody'
                            key={i.id}
                        >
                        <JournalsList
                            journalsList={i}
                            deleteJournal={updateJournalStatus}
                        />
                        </tbody>
                    )}
                </table>
            </div>
        </section>
    );
};

export default JournalsSettingsPage;