import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../../../constants/constants";
import Sbornik from "./sbornik/sbornik";

export interface SborniksInterface{
    id: number,
    name: string,
    file_url: string,
    file_card_url: string,
    type_file: string,
    file_extension: string,
    create_date: Date,
    category_id: number,
    category_name: string
}
const Sborniks = () => {
    const [sborniks, setSborniks] = useState<SborniksInterface[]>([])

    useEffect(() => {
        fetch(`${baseUrl}manuals`)
          .then((res) => res.json())
            .then((data: SborniksInterface[]) => {
             const filteredData = data.filter((item) => item.category_name === "Сборники профилактических мероприятий")
            setSborniks(filteredData);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    return (
            <section className="sborniks">
                {
                    sborniks && sborniks.length > 0 ?
                        sborniks.map((item) =>
                        <Sbornik key={item.id} {...item}/>
                    )
                        :
                        <h2 className='notFound-title'>Методички не найдены</h2>
                }
            </section>
    );
};

export default Sborniks;