import React, {useEffect, useState} from 'react';
import BlankForum from "./components/GetBlankForum/BlankForum";
import './applicationsPage.scss'
import BlankOnline from "./components/GetBlankOnline/BlankOnline";
import BlankOffline from "./components/GetBlankOffline/BlankOffline";
import BlankWriter from "./components/GetBlankWriter/BlankWriter";
import {baseUrl} from "../../../../constants/constants";
import {
    dwnldCourse,
    dwnldForums,
    dwnldOfflineCourse,
    dwnldWriter
} from "../../../../components/excel-export/excel-export";

export interface IBlankForum{
    id: number,
    email: string,
    full_name: string,
    full_name_declination: string,
    age: number,
    number_phone: string,
    region_residence: string,
    place_of_work: string,
    position_work: string,
    travel_certificate: boolean,
    forum_id: number,
    visit_format: {
        id: 0,
        name: string,
    },
    forum:{
        id: number,
        name: string
    }
    date_creation: Date,
    questions: string
}
export interface IBlankOnlineCourse{
    id: number,
    email: string,
    full_name: string,
    full_name_declination: string,
    age: number,
    number_phone: string,
    place_of_work: string,
    position_work: string,
    higher_education: boolean,
    buy_certificate: boolean,
    online_course_id: number,
    date_creation: Date,
    online_course:{
        id: number,
        name: string
    }
}

export interface IBlankOfflineCourse{
    id: number,
    contact_email: string,
    contact_full_name: string,
    contact_number_phone: string,
    event_location: string,
    topics_discussion: string,
    desired_time: Date,
    date_creation: Date,
    offline_course:{
        id: number,
        name: string
    }
}

export interface IBlankWriter{
    id: number,
    email: string,
    full_name: string,
    number_phone: string,
    title_article: string,
    busyness: string,
    file_article_url: string,
    file_data_user_url: string,
    file_consent_data_processing_url: string,
    file_anti_plagiarism_url: string,
    date_creation: Date,
    theme_article:{
        id: number,
        name: string
    }
}

const ApplicationsPage = () => {
    const [forumBlanks, setForumBlanks] = useState<IBlankForum[]>([]);
    const [onlineBlanks, setOnlineBlanks] = useState<IBlankOnlineCourse[]>([]);
    const [offlineBlanks, setOfflineBlanks] = useState<IBlankOfflineCourse[]>([]);
    const [writerBlanks, setWriterBlanks] = useState<IBlankWriter[]>([]);

    useEffect(() => {
        fetch(`${baseUrl}blank_forum?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setForumBlanks(data)
            })
            .catch((err) =>{
                console.log(err)
            });

        fetch(`${baseUrl}blank_online_course?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setOnlineBlanks(data)
            })
            .catch((err) =>{
                console.log(err)
            });

        fetch(`${baseUrl}blank_offline_course?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setOfflineBlanks(data)
            })
            .catch((err) =>{
                console.log(err)
            });

        fetch(`${baseUrl}blank_writer?sort_date=true`)
            .then((res) => res.json())
            .then((data) =>{
                setWriterBlanks(data)
        })
            .catch((err)=>{
                console.log(err)
            })
    }, []);



    return (
        <>
            <h1 className="admin-title__h1">Редактирование секции "Заявки"</h1>
            <div className="one-table">
                <div className="admin-buttons">
                    <h3 className='admin-title__h3'>Форумы</h3>
                    <button className="button admin-btn" onClick={dwnldForums}>Excel</button>
                </div>
                <table className='applications-table'>
                    <thead className='applications-table__thead'>
                        <tr>
                            <th className='applications-table__thead-th'>Название форума</th>
                            <th className='applications-table__thead-th'>Email</th>
                            <th className='applications-table__thead-th'>Имя</th>
                            <th className='applications-table__thead-th'>Имя в родительном падеже</th>
                            <th className='applications-table__thead-th'>Возраст</th>
                            <th className='applications-table__thead-th'>Телефон</th>
                            <th className='applications-table__thead-th'>Регион</th>
                            <th className='applications-table__thead-th'>Место работы</th>
                            <th className='applications-table__thead-th'>Должность</th>
                            <th className='applications-table__thead-th'>Командировочное</th>
                            <th className='applications-table__thead-th'>Формат форума</th>
                            <th className='applications-table__thead-th'>Дата подачи</th>
                        </tr>
                    </thead>
                    {forumBlanks.map((item) =>
                        <tbody className='applications-table__tbody' key={item.id}>
                        <BlankForum{...item}/>
                        </tbody>
                    )}
                </table>
            </div>
            <div className="one-table">
                <div className="admin-buttons">
                    <h3 className='admin-title__h3'>Онлайн-курсы</h3>
                    <button className="button admin-btn" onClick={dwnldCourse}>Excel</button>
                </div>
                <table
                    className='applications-table'
                >
                    <thead className='applications-table__thead'>
                    <tr>
                        <th className='applications-table__thead-th'>Название курса</th>
                        <th className='applications-table__thead-th'>Email</th>
                        <th className='applications-table__thead-th'>Имя</th>
                        <th className='applications-table__thead-th'>Имя в родительном падеже</th>
                        <th className='applications-table__thead-th'>Возраст</th>
                        <th className='applications-table__thead-th'>Телефон</th>
                        <th className='applications-table__thead-th'>Место работы</th>
                        <th className='applications-table__thead-th'>Должность</th>
                        <th className='applications-table__thead-th'>Высшее образование</th>
                        <th className='applications-table__thead-th'>Сертификат</th>
                        <th className='applications-table__thead-th'>Дата подачи</th>
                    </tr>
                    </thead>
                    {onlineBlanks.map((item) =>
                        <tbody className='applications-table__tbody' key={item.id}>
                        <BlankOnline {...item}/>
                        </tbody>
                    )}
                </table>
            </div>
            <div className="one-table">
                <div className="admin-buttons">
                    <h3 className='admin-title__h3'>Оффлайн курсы</h3>
                    <button className="button admin-btn" onClick={dwnldOfflineCourse}>Excel</button>
                </div>
                <table
                    className='applications-table'
                >
                    <thead className='applications-table__thead'>
                        <tr>
                            <th className='applications-table__thead-th'>Мероприятие</th>
                            <th className='applications-table__thead-th'>Email</th>
                            <th className='applications-table__thead-th'>Имя</th>
                            <th className='applications-table__thead-th'>Телефон</th>
                            <th className='applications-table__thead-th'>Место проведения мероприятия</th>
                            <th className='applications-table__thead-th'>Темы для обсуждения</th>
                            <th className='applications-table__thead-th'>Дата проведения</th>
                            <th className='applications-table__thead-th'>Дата подачи</th>
                        </tr>
                    </thead>
                    {offlineBlanks.map((item) =>
                        <tbody className='applications-table__tbody'  key={item.id}>
                        <BlankOffline {...item}/>
                        </tbody>
                    )}
                </table>
            </div>
            <div className="one-table">
                <div className="admin-buttons">
                    <h3 className='admin-title__h3'>Обзор.НЦПТИ</h3>
                    <button className="button admin-btn" onClick={dwnldWriter}>Excel</button>
                </div>
                <table
                    className='applications-table'
                >
                    <thead className='applications-table__thead'>
                        <tr>
                            <th className='applications-table__thead-th'>Выпуск, на который направлена статья</th>
                            <th className='applications-table__thead-th'>Имя</th>
                            <th className='applications-table__thead-th'>Email</th>
                            <th className='applications-table__thead-th'>Телефон</th>
                            <th className='applications-table__thead-th'>Место работы</th>
                            <th className='applications-table__thead-th'>Название статьи</th>
                            <th className='applications-table__thead-th'>Файл статьи</th>
                            <th className='applications-table__thead-th'>Файл антиплагиата</th>
                            <th className='applications-table__thead-th'>Файл с данными о пользователе</th>
                            <th className='applications-table__thead-th'>Файл с согласием на обработку ПДН</th>
                            <th className='applications-table__thead-th'>Дата подачи</th>
                        </tr>
                    </thead>
                    {writerBlanks.map((item) =>
                        <tbody className='applications-table__tbody' key={item.id}>
                        <BlankWriter {...item}/>
                        </tbody>
                    )}
                </table>
            </div>
        </>
    );
};

export default ApplicationsPage;