import React, {useEffect, useState} from 'react';
import CreateOnlineCourse from "./components/CreateNewOnlineCourse/CreateOnlineCourse";
import OnlineCourseList from "./components/OnlineCoursesList/OnlineCourseList";
import './CoursesSettingsPage.scss'
import {baseUrl} from "../../../../constants/constants";
import Modal from "../../../../components/modal/modal";
import {successToast} from "../../../../shared/toastify";

export interface IOnlineCourseSettings{
    id: number,
    name: string,
    description: string,
    price: number,
    image_url: string,
    is_active: boolean,
    is_archive: boolean,
    publish_date: Date
}


const CoursesSettingsPage = () => {
    const [showOnlineModal, setShowOnlineModal] = useState(false)
    const [onlineCourses, setOnlineCourses] = useState<IOnlineCourseSettings[]>([])

    useEffect(() => {
        fetch(`${baseUrl}online_course`)
            .then((res) => res.json())
            .then((data) => {
                setOnlineCourses(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    async function deleteCurrentCourse(id: number){
        try{
            const res = await fetch(`${baseUrl}online_course/${id}`, {
                method: 'DELETE'
            });
            successToast('Курс удалён')
            return res.ok
        } catch (err){
            console.log(err)
        }
    }

    const deleteCourse = (id: number) => {
        setOnlineCourses(onlineCourses.filter((onlineCourses) => onlineCourses.id !== id))
    }

    const updateCourseStatus = (currentCourse: IOnlineCourseSettings) => {
        deleteCurrentCourse(currentCourse.id);
        setOnlineCourses([...onlineCourses, {...currentCourse, is_archive: false}]);
        deleteCourse(currentCourse.id)
    }

    const closeModal = () => {
        setShowOnlineModal(false);
    }

    return (
        <>
            <h1 className="admin-title__h1">Редактирование секции "Курсы"</h1>
            <div className="admin-buttons">
                <p className="admin-title__p">Добавить онлайн курс:</p>
                <button
                    className="button admin-btn"
                    onClick={() => setShowOnlineModal(true)}
                >
                    Добавить
                </button>
                {showOnlineModal &&
                    <Modal
                        active={showOnlineModal}
                        title='Добавьте информацию о курсе'
                        сlose={closeModal}
                    >
                        <CreateOnlineCourse close={closeModal}/>
                    </Modal>
                }
            </div>

            {/*Список курсов*/}
            <h2 className="admin-title__h2">Список курсов: </h2>
             <div className="admin-div">
                {onlineCourses.map((i) => (
                    <OnlineCourseList
                        key={i.id}
                        on_courses={i}
                        deleteCourse={updateCourseStatus}
                    />
                ))}
            </div>
        </>
    );
};

export default CoursesSettingsPage;