import React from 'react';
import './cards.scss';
import {useEffect, useState} from "react";
import {baseUrl} from "../../../../constants/constants";
import CardsRouter from "./components/cardsRouter";
import {Link} from "react-router-dom";

interface CategoriesInterface{
    id: number,
    name: string,
    type_category: string
}

interface IBanner{
    id: number;
    name: string,
    file_url: string
}

const Cards = () => {
    const [banner, setBanner] = useState<IBanner[]>([])
    const [mobileBanner, setMobileBanner] = useState<IBanner[]>([])
    const[categories, setCategories] = useState<CategoriesInterface[]>([]);

    useEffect(() => {
        fetch(`${baseUrl}gallery_images?category_id=19`)
            .then((res) => res.json())
            .then((data: IBanner[]) => {
                const banner = data.filter((item) => item.id === 71)
                setBanner(banner)
                console.log(banner)
            })

        fetch(`${baseUrl}gallery_images?category_id=19`)
            .then((res) => res.json())
            .then((data: IBanner[]) => {
                const bannerMobile = data.filter((el) => el.id === 80)
                setMobileBanner(bannerMobile)
            })
    }, []);


    useEffect(() => {
        fetch(`${baseUrl}category?type_category=memo`)
            .then((res)=> res.json())
            .then((data) => {
                setCategories(data)
            })
            .catch((err) => {
                console.log(err.message)
            })
     }, [])


    return (
        <div className='page-memos'>
            {banner && banner.map((i) => (
                <section className='title-image'>
                    <img
                        src={`${baseUrl}${i.file_url}`}
                        alt="Баннер Наши проекты"
                        loading='lazy'
                        className='title-image__img'
                    />
                </section>
            ))
            }

            {mobileBanner && mobileBanner.map((e) => (
                <section className='title-image__mobile'>
                    <img
                        src={`${baseUrl}${e.file_url}`}
                        alt="Баннер Наши проекты мобильный"
                        loading='lazy'
                        className='title-image__img-mobile'
                    />
                </section>
            ))}

            <div className='page'>
                {
                    categories && categories.map(item =>
                        <CardsRouter id={item.id} name={item.name} key={item.id}/>
                    )
                }
            </div>
        </div>
    );
};

export default Cards;