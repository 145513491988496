import React, {useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {ICategoryManuals, IManualsSettings} from "../ManualsSettings";
import {baseUrl} from "../../../../../../../constants/constants";
import {errorToast, successToast} from "../../../../../../../shared/toastify";

interface INewInfoManual{
    name: string,
    file: string,
    file_card: string,
    category_id: string
}

interface ICurrentManual{
    manual: IManualsSettings,
    category: ICategoryManuals[],
    close: () => void
}

const UpdateManual: React.FC<ICurrentManual> = ({close, category, manual}) => {
    const [values, setValues] = useState('')
    const {
        register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<INewInfoManual>({
        defaultValues:{
            name: manual.name,
            category_id: String(manual.category_id)
        }
    })


    const submit: SubmitHandler<INewInfoManual> = async (data: any) => {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'file' || key == 'file_card'){
                    if (!!data[key][0]){
                        formData.append(key, data[key][0])
                    }
                } else {formData.append(key, data[key])}
            }
            const res = await fetch (`${baseUrl}manuals/${manual.id}`, {
                method: 'PUT',
                body: formData
            })
            if (res.status === 200){
                successToast('Загружена новая информация о методичке. Пожалуйста, обновите страницу')
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <textarea
                className='regInput'
                placeholder='Название методички'
                {...register('name')}
            />
            <label
                className="regLabel"
            >
                Категория:
                <select
                    id='category-manuals'
                    value={values}
                    {...register('category_id', {required: true})}
                    onChange={(e) => setValues(e.target.value)}
                >
                    {category.map((i) => <option value={i.id}>{i.name}</option>)}
                </select>
            </label>
            <span className="regInput__file">
                <p>Документ методички</p>
                <input
                    type="file"
                    {...register('file')}
                    accept='application/msword, application/pdf'
                />
            </span>
            <span className="regInput__file">
                <p>Изображение методички</p>
                <input
                    type="file"
                    {...register('file_card')}
                    accept='image/jpg, image/jpeg, image/webp, image/png'
                />
            </span>
            <button className='button form-btn'>Обновить</button>
        </form>
    );
};

export default UpdateManual;