import React from 'react';
import './home.scss';
import { Link } from 'react-router-dom'
import NAK from '../../assets/partners/naczionalnyj-antiterroristicheskij-kommitet.webp'
import MNO from '../../assets/partners/ministerstvo-nauki-i-vysshego-obrazovaniya.webp'
import MVD from '../../assets/partners/mvd-rossii.webp'
import SKPP from '../../assets/partners/sledstvennyj-kommitet-pri-prokurature.webp'
import FSB from '../../assets/partners/federalnaya-sluzhba-bezopasnosti.webp'
import GPRF from '../../assets/partners/generalnaya-prokuratura-rf.webp'
import Rosmolodezh from '../../assets/partners/rosmolodyozh.webp'
import ODKB from '../../assets/partners/odkb.webp'
import RKN from '../../assets/partners/rkn.webp'
import ATC from '../../assets/partners/atcz.webp'
import About from '../../assets/cards/about.webp'
import Projects from '../../assets/cards/projects_.webp';
import Courses from '../../assets/cards/courses_.webp';
import Education from '../../assets/cards/education_.webp';
import Journal from '../../assets/cards/journal_.webp';
import Forums from '../../assets/cards/forums_.webp';
import Map from '../../assets/cards/map_.webp';
import Materials from '../../assets/cards/materials_.webp';
import Team from '../../assets/cards/team_.webp';
import AboutMobile from '../../assets/cards/mobile/about-mobile.webp'
import MaterialsMobile from '../../assets/cards/mobile/materials-mobile_.webp';
import ProjectsMobile from '../../assets/cards/mobile/projects-mobile_.webp';
import ForumsMobile from '../../assets/cards/mobile/forums-mobile_.webp';
import CoursesMobile from '../../assets/cards/mobile/courses-mobile_.webp';
import EducationMobile from '../../assets/cards/mobile/education-mobile_.webp';
import JournalMobile from '../../assets/cards/mobile/journal-mobile_.webp';
import TeamMobile from '../../assets/cards/mobile/team-mobile_.webp';
import MapMobile from '../../assets/cards/mobile/map-mobile_.webp';


const Home = () => {
    return (
        <>
            <div className="homepage">

            {/*компьютерная версия меню    */}
            <section className="pageCards">
                <div className="pageCards__upper">
                    <div className="pageCards__upper-left">
                        {/*<img src={About} alt="Карточка Медиа материалы" className='about-card' loading='lazy'/>*/}
                        <div className='about-card'>
                            <h1 className='about-card__title'>НЦПТИ</h1>
                            <p className='about-card__text'><b>Национальный центр информационного противодействия терроризму и экстремизму в образовательной среде и сети Интернет</b> — это векторный центр, работающий на формирование антиэкстремистского и антитеррористического сознания в молодёжной среде, на совершенствование работы по информационной поддержке антитеррористических и антиэкстремистских мероприятий в сети Интернет и созданию позитивного профилактического контента</p>
                        </div>
                    </div>
                    <div className="pageCards__upper-center">
                        <Link to='/materialy'>
                        <img src={Materials} alt="Карточка Медиа материалы" className='media-materials' loading='lazy'/>
                        </Link>
                        <Link to='/nashi-proekty'>
                            <img src={Projects} alt="Карточка Наши проекты" className='our-projects' loading='lazy'/>
                        </Link>
                    </div>
                    <div className="pageCards__upper-right">
                        <div className="pageCards__upper-link">
                        <Link to='/forums'>
                            <img src={Forums} alt="Карточка Наши мероприятия" className='our-events' loading='lazy'/>
                        </Link>
                        </div>
                        <div className="pageCards__upper-link">
                        <Link to='/online-courses'>
                            <img src={Courses} alt="Карточка Онлайн курсы" className='online-courses' loading='lazy'/>
                        </Link>
                        </div>
                    </div>
                </div>
                <div className="pageCards__lower">
                    <div className="pageCards__lower-link">
                        <Link to='/education'>
                            <img src={Education} alt="Карточка очное обучение" className='ochnoe' loading='lazy'/>
                        </Link>
                    </div>
                    <div className="pageCards__lower-link">
                        <Link to='obzor'>
                            <img src={Journal} alt="Карточка Обзон.НЦПТИ" className='obzor' loading='lazy'/>
                        </Link>
                    </div>
                    <div className="pageCards__lower-link">
                        <Link to='about-us'>
                            <img src={Team} alt="Карточка Сеть центров" className='about' loading='lazy'/>
                        </Link>
                    </div>
                    <div className="pageCards__lower-link">
                    <a href="https://map.ncpti.ru" target='_blank'>
                        <img src={Map} alt="Карточка Интерактивная карта" className='map-ncpti' loading='lazy'/>
                    </a>
                    </div>
                </div>
            </section>

                {/*мобильная версия меню    */}
            <section className="page-cards__mobile">
                <div className="page-cards__mobile-upper">
                    <img src={AboutMobile} alt="Карточка Информация о НЦПТИ" className='page-cards__mobile-upper-img' loading='lazy'/>
                    <Link to='/materialy'>
                        <img src={MaterialsMobile} alt="Карточка Медиа материалы" className='page-cards__mobile-upper-img' loading='lazy'/>
                    </Link>
                    <Link to='/nashi-proekty'>
                        <img src={ProjectsMobile} alt="Карточка Наши проекты" className='page-cards__mobile-upper-img' loading='lazy'/>
                    </Link>
                    <Link to='/forums'>
                        <img src={ForumsMobile} alt="Карточка Наши мероприятия" className='page-cards__mobile-upper-img' loading='lazy'/>
                    </Link>
                    <Link to='/online-courses'>
                        <img src={CoursesMobile} alt="Карточка Онлайн курсы" className='page-cards__mobile-upper-img' loading='lazy'/>
                    </Link>
                </div>
                <div className="page-cards__mobile-lower">
                    <div className="page-cards__mobile-lower-first">
                        <Link to='/education'>
                            <img src={EducationMobile} alt="Карточка Медиа материалы" className='page-cards__mobile-lower-img' loading='lazy'/>
                        </Link>
                        <Link to='/obzor'>
                            <img src={JournalMobile} alt="Карточка Наши проекты" className='page-cards__mobile-lower-img' loading='lazy'/>
                        </Link>
                    </div>
                    <div className="page-cards__mobile-lower-second">
                        <Link to='/about-us'>
                            <img src={TeamMobile} alt="Карточка Наши мероприятия" className='page-cards__mobile-lower-img' loading='lazy'/>
                        </Link>
                        <a href="https://map.ncpti.ru" target='_blank'>
                            <img src={MapMobile} alt="Карточка Онлайн курсы" className='page-cards__mobile-lower-img' loading='lazy'/>
                        </a>
                    </div>
                    </div>
            </section>

                <section className="partners">
                <div>
                    <h2 className="partners-logos__title">Наши партнёры</h2>
                    <div className="partners-logos">
                    <a href="http://nac.gov.ru" target='_blank'>
                        <img src={NAK} alt="Национальный антитеррористический комитет"
                             title="Национальный антитеррористический комитет" loading='lazy'/>
                    </a>
                    <a href="https://minobrnauki.gov.ru" target='_blank'>
                        <img src={MNO} alt="Министерство науки и образования" title="Министерство науки и образования" loading='lazy'/>
                    </a>
                    <a href="https://мвд.рф" target='_blank'>
                        <img src={MVD} alt="Министерство внутренних дел" title="Министерство внутренних дел" loading='lazy'/>
                    </a>
                    <a href="https://sledcom.ru" target='_blank'>
                        <img src={SKPP} alt="Следственный комитет при прокуратуре"
                             title="Следственный комитет при прокуратуре" loading='lazy'/>
                    </a>
                    <a href="http://www.fsb.ru" target='_blank'>
                        <img src={FSB} alt="Федеральная служба безопасности" title="Федеральная служба безопасности" loading='lazy'/>
                    </a>
                    <a href="https://epp.genproc.gov.ru/web/gprf" target='_blank'>
                        <img src={GPRF} alt="Генеральная прокуратура РФ" title="Генеральная прокуратура РФ" loading='lazy'/>
                    </a>
                    <a href="https://fadm.gov.ru" target='_blank'>
                        <img src={Rosmolodezh} alt="Росмолодёжь" title="Росмолодёжь" loading='lazy'/>
                    </a>
                    <a href="https://odkb-csto.org" target='_blank'>
                        <img src={ODKB} alt="ОДКБ" title="ОДКБ" loading='lazy'/>
                    </a>
                    <a href="https://new.rkn.gov.ru" target='_blank'>
                        <img src={RKN} alt="Роскомнадзор" title="Роскомнадзор" loading='lazy'/>
                    </a>
                    <a href="https://www.cisatc.org" target='_blank'>
                        <img src={ATC} alt="АТЦ" title="АТЦ" loading='lazy'/>
                    </a>
                    </div>
                </div>
            </section>
            </div>
            <section className="contacts">
                <div className="contacts-text">
                    <h2 className='contacts-text__title'>НАШИ <br/>КОНТАКТЫ</h2>
                    <a href="tel:88632012822" className='contacts-a'>+7 (863) 201-28-22</a>
                    <p className='contacts-p'><a href='mailto:info@ncpti.ru'>info@ncpti.ru</a></p>
                    <p className='contacts-p'>344011, г.Ростов-на-Дону, ул.Города Волос, д.6, а/я 488</p>
                </div>
                <div className="contacts-map">
                   <a className='firstA'
                        href="https://yandex.ru/maps/39/rostov-na-donu/?utm_medium=mapframe&utm_source=maps"
                        >Ростов‑на‑Дону</a><a className='secondA'
                        href="https://yandex.ru/maps/39/rostov-na-donu/house/ulitsa_goroda_volos_6/Z0AYcQdmSEQHQFptfX5zdn9nZQ==/?ll=39.706783%2C47.226510&source=serp_navig&utm_medium=mapframe&utm_source=maps&z=18.06"
                        >Улица Города Волос, 6 — Яндекс
                        Карты</a>
                        <iframe
                            src="https://yandex.ru/map-widget/v1/?ll=39.706783%2C47.226510&mode=whatshere&source=serp_navig&whatshere%5Bpoint%5D=39.705972%2C47.227344&whatshere%5Bzoom%5D=17&z=18.06"
                             frameBorder="1"
                            className='map-iframe'
                           ></iframe>
                    </div>
            </section>
        </>
    );
};

export default Home;