import React, {useEffect, useState} from 'react';
import CreateNewCard from "./CreateNewCard/CreateNewCard";
import CardsList from "./CardsList/CardsList";
import {baseUrl} from "../../../../../../constants/constants";
import Modal from "../../../../../../components/modal/modal";
import {Link} from "react-router-dom";
import {successToast} from "../../../../../../shared/toastify";

export interface ICardsSettings{
    id: number,
    name: string,
    file_url: string,
    file_card_url: string,
    type_file: string,
    file_extension: string,
    create_date: Date,
    category_id: string,
    category_name: string
}

export interface ICategoryCards{
    id: number,
    name: string,
}

const CardsSettings = () => {
    const [cards, setCards] = useState<ICardsSettings[]>([])
    const [categoryCard, setCategoryCard] = useState<ICategoryCards[]>([])
    const [showCardsModal, setShowCardsModal] = useState(false)

    useEffect(() => {
        fetch(`${baseUrl}memos?page=1&page_size=200&sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setCards(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    useEffect(() => {
        fetch(`${baseUrl}category?type_category=memo`)
            .then((res) => res.json())
            .then((data) => {
                setCategoryCard(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    async function deleteCurrentCard(id: number){
        try{
            const res = await fetch(`${baseUrl}memos/${id}`, {
                method: 'DELETE'
            });
            successToast('Карточка удалена!')
            return res.ok
        } catch (err){
            console.log(err)
        }
    }

    const deleteCard = (id: number) => {
        setCards(cards.filter((cards) => cards.id !== id))
    }

    const updateCardStatus = (currentCard: ICardsSettings) => {
        deleteCurrentCard(currentCard.id);
        setCards([...cards, {...currentCard}]);
        deleteCard(currentCard.id)
    }

    const closeModal = () => {
        setShowCardsModal(false)
    }

    return (
    <>
        <section className="admin-section">
            <h1 className="admin-title__h1-media">Редактирование секции "Медиаматериалы"</h1>
            <section className="admin-media__btns-section">
                <Link to='/admin-panel/media/video'>
                    <button className="button admin-media__btn">Видео</button>
                </Link>
                <Link to='/admin-panel/media/manuals'>
                    <button className="button admin-media__btn">Методички</button>
                </Link>
                <Link to='#'>
                    <button className="button admin-media__btn-active">Карточки</button>
                </Link>
            </section>
            <div className="admin-buttons">
                <p className="admin-title__p">Добавить карточку: </p>
                <button
                    className="button admin-btn"
                    onClick={() => setShowCardsModal(true)}
                >
                    Добавить
                </button>
                {showCardsModal &&
                    <Modal
                        active={showCardsModal}
                        title='Введите данные для карточки'
                        сlose={closeModal}
                    >
                        {
                            <CreateNewCard
                                category={categoryCard}
                                close={closeModal}
                            />
                        }
                    </Modal>
                }
            </div>
            <div className="one-table">
                <table className="applications-table">
                    <thead className='applications-table__thead'>
                    <tr>
                        <th className="applications-table__thead-th">Категория</th>
                        <th className="applications-table__thead-th">Картинка</th>
                        <th className="applications-table__thead-th">Название</th>
                        <th className="applications-table__thead-th">Файл</th>
                        <th className="applications-table__thead-th">Действия</th>
                    </tr>
                    </thead>
                    {cards.map((i) => (
                        <tbody
                            className="applications-table__tbody"
                            key={i.id}
                        >
                        <CardsList
                            cardsList={i}
                            category={categoryCard}
                            deleteCard={updateCardStatus}
                        />
                        </tbody>
                    ))}
                </table>
            </div>
        </section>
    </>
    );
};

export default CardsSettings;