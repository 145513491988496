import React, {useState} from 'react';
import {ICardsSettings, ICategoryCards} from "../CardsSettings";
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../../constants/constants";
import {errorToast, successToast} from "../../../../../../../shared/toastify";

interface INewInfoCard {
    name: string,
    file: string,
    file_card: string,
    category_id: string
}

interface ICurrentInfoCard{
    card: ICardsSettings,
    category: ICategoryCards[],
    close: () => void
}

const UpdateCard:React.FC<ICurrentInfoCard> = ({card, category, close}) => {
    const{
        register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<INewInfoCard>({
        defaultValues:{
            name: card.name,
            category_id: String(card.category_id)
        }
    })
    const [values, setValues] = useState('')

    const submit: SubmitHandler<INewInfoCard> = async (data: any) => {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'file' || key == 'file_card'){
                    if (!!data[key][0]){
                        formData.append(key, data[key][0])
                    }
                } else {formData.append(key, data[key])}
            }
            const res = await fetch(`${baseUrl}memos/${card.id}`, {
                method: 'PUT',
                body: formData
            })
            if (res.status === 200){
                successToast('Загружена новая информация о карточке. Пожалуйста, обновите страницу')
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message:''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <textarea
                className='regInput'
                placeholder='Название карточки'
                {...register('name')}
            />
            <label
                className="regLabel"
            >
                Категория:
                <select
                    id='category-cards'
                    value={values}
                    {...register('category_id', {required: true})}
                    onChange={(e) => setValues(e.target.value)}
                >
                    {category.map((i) => <option value={i.id}>{i.name}</option>)}
                </select>
            </label>
            <span className="regInput__file">
                <p>Выберите файл карточки</p>
                <input
                    type="file"
                    {...register('file')}
                    accept='application/zip, application/msword, application/pdf'
                />
            </span>
            <span className="regInput__file">
                <p>Выберите изображение карточки</p>
                <input
                    type="file"
                    {...register('file_card')}
                    accept='image/jpg, image/jpeg, image/webp, image/png'
                />
            </span>
            <button className="button form-btn">Обновить</button>
        </form>
    );
};

export default UpdateCard;