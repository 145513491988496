import React, {useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {ICategoryVideos} from "../VideoSettings";
import {baseUrl} from "../../../../../../../constants/constants";
import {errorToast, successToast} from "../../../../../../../shared/toastify";

interface ICreateVideo{
    name: string,
    video_url: string,
    category_id: number
}

interface Props{
    category: ICategoryVideos[],
    close: () => void
}

const CreateNewVideo = ({category, close}: Props) => {
    const {
        register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<ICreateVideo>()
    const[values, setValues] = useState('')

    const submit: SubmitHandler<ICreateVideo> = async(data) => {
        try{
            const res = await fetch(`${baseUrl}gallery_video`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            if(res.status === 200){
                successToast('Новое видео опубликовано! Пожалуйста, обновите страницу')
                close()
            } else if(res.status === 401){
                setError('root', {type: 'custom', message:''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <input
                type="text"
                className="regInput"
                placeholder='Название видео'
                {...register('name', {required: true})}
            />
            {errors.name && <p className='wrong-message'>Это поле обязательно</p>}

            <input
                type="text"
                className="regInput"
                placeholder='Ссылка на видео (брать из "отправить" -> iframe)'
                {...register('video_url', {required: true})}
            />
            {errors.video_url && <p className='wrong-message'>Это поле обязательно</p>}

            <label
                className="regLabel"
            >
                Категория:
                <select
                    id='category-manuals'
                    value={values}
                    {...register('category_id', {required: true})}
                    onChange={(e) => setValues(e.target.value)}
                >
                    {category.map((i) => <option value={i.id}>{i.name}</option>)}
                </select>
            </label>
            {errors.category_id && <p className='wrong-message'>Это поле обязательно</p>}

            <button className='button form-btn'>Опубликовать</button>
        </form>
    );
};

export default CreateNewVideo;