import React from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../constants/constants";
import Login from "../login";
import {useNavigate} from "react-router-dom";
import './registration.scss'
import {errorToast, successToast} from "../../../shared/toastify";

export function checkErrorText(inputName: string, errors: any) {
    return errors?.[inputName] && errors?.[inputName]?.message;
}

interface Props{
    auth: boolean,
    setAuth:  React.Dispatch<React.SetStateAction<boolean>>;
}

const Registration = ({setAuth}: Props) => {
    const {register,
        handleSubmit,
        formState: {errors},
        setError
    } = useForm<Login>();
    const navigate = useNavigate()
    const submit: SubmitHandler<Login> = async (data) => {
        try {
            const res = await fetch(`${baseUrl}user/register`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            });
            if (res.status === 200) {
                localStorage.setItem('access', 'yes');
                setAuth(true)

                successToast('Вы успешно зарегестрировались!')
                navigate('/account');
            } else if (res.status === 401) {
                setError('root', {type: 'custom', message: ''})
            } else if (res.status === 409){
                setError('root', {type: 'sameEmail', message: ''})
                errorToast('Пользователь с такой почтой уже существует')
            } else if (res.status === 422){
                setError('root', {type: 'wrong-password', message: 'Пароль должен состоять минимум из 8 символов, включать в себя спецсимволы, цифры и заглавные буквы'})
            }
        } catch (err){
            console.error(err)
        }
    }

    return (
        <div className='page'>
            <form onSubmit={handleSubmit(submit)} className='register-form'>
                <h1 className="register-title">Регистрация</h1>
                <input type="email" placeholder='Введите ваш Email'
                       className='register-input' {...register('email', {required: true})}/>
                {errors.email && errors.email.type === "required" && (
                    <p className='wrong-message'>Это поле обязательно</p>
                )}
                {/*{errors.root && <p className='wrong-message'>Пользователь с такой почтой уже существует</p>}*/}
                <input type="text" placeholder='Придумайте пароль'
                       className='register-input' {...register('password', {required: true})}/>
                {errors.password && errors.password.type === "required" && (
                    <p className='wrong-message'>Это поле обязательно</p>
                )}
                <div className="register-approval">
                    <span
                        className='regInput__checkbox'
                        style={{margin: '.5rem 0'}}
                    >
                        <input type="checkbox" className='regInput__checkbox-galka' required/>
                    <label>
                        <a
                           href="/soglasie.doc"
                           download
                           className='register-approval__link'
                        >Согласие </a>на обработку персональных данных
                    </label>
                </span>
                    <span className='regInput__checkbox'>
                    <input type="checkbox" className='regInput__checkbox-galka' required/>
                    <label>
                        Ознакомлен с <a href="/politika-konfidenczialnosti.docx" download className='register-approval__link'>
                            политикой конфиденциальности
                        </a>
                    </label>
                </span>
                </div>
                <div className="btns">
                    <button className='button register-btn'>Регистрация</button>
                </div>
                {errors?.root && <p className='wrong-message'>{errors.root.message}</p>}
            </form>
        </div>
    );
};

export default Registration;