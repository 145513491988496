import React from 'react';
import {ParallelsVideoInterface} from "../video__parallels/parallels";

const ParallelsOneVideo = ({id, name, video_url, create_date, category_id, category_name}: ParallelsVideoInterface) => {
    return (
            <div className="video-section">
                <iframe
                    className='video'
                    src={video_url}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <p className='video-text'>{name}</p>
            </div>
    );
};

export default ParallelsOneVideo;