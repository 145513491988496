import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../../../constants/constants";
import CheckList from "./check-list/checkList";

export interface CheckListInterface{
    id: number,
    name: string,
    file_url: string,
    file_card_url: string,
    type_file: string,
    file_extension: string,
    create_date: Date,
    category_id: number,
    category_name: string
}

const CheckLists = () => {
    const [checkLists, setCheckLists] = useState<CheckListInterface[]>([])

    useEffect(() => {
        fetch(`${baseUrl}manuals`)
            .then((res) => res.json())
            .then((data: CheckListInterface[]) => {
                const filteredData = data.filter((item) => item.category_name === "Чек-листы по работе с группами риска")
                setCheckLists(filteredData)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    return (
            <section className="checkLists">
                {
                    checkLists && checkLists.length > 0 ?
                        checkLists.map((item) =>
                        <CheckList key={item.id} {...item}/>
                        )
                        :
                        <h2 className='notFound-title'>Методички не найдены</h2>
                }
            </section>
    );
};

export default CheckLists;