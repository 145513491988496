import React from 'react';
import {useAuth} from "../../../components/authProvider/authProvider";
import {Outlet} from "react-router-dom";
import AdminNavPanel from "./AdminNavPanel/adminNavPanel";

const AdminLayout = () => {

    return (
        <>
                <section>
                    <aside className='admin-page__sidebar'>
                        <AdminNavPanel/>
                    </aside>
                    <section className='admin-page__content'>
                        <Outlet/>
                    </section>
                </section>
        </>
    );
};

export default AdminLayout;