import React from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../constants/constants";
import {errorToast, successToast} from "../../../../shared/toastify";

interface INewsBanner{
    name: string,
    file: string,
}

interface Props{
    close: () => void
}

const UpdateBannerForm = ({close}: Props) => {
    const{register,
        handleSubmit,
        formState:{errors},
        setError} = useForm<INewsBanner>()

    const handleChange = (event:{target:{files: any};}) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<INewsBanner> = async(data: any) => {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'file'){
                    if (!!data[key][0]){
                        formData.append(key, data[key][0])
                    }
                } else {formData.append(key, data[key])}
            }
            const res = await fetch(`${baseUrl}gallery_images/not-converter/66`, {
                method: "PUT",
                body: formData
            });
            if(res.status === 200){
                successToast('Баннер успешно заменён');
                close()
            } else if(res.status === 401){
                setError('root', {type: 'custom', message: ''})
                errorToast('Error 401!')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            onSubmit={handleSubmit(submit)}
            className='regForm'
        >
            <input
                type="text"
                className='regInput'
                placeholder='Введите название'
                defaultValue='news-banner'
                style={{display: 'none'}}
                {...register('name', {required: true})}
            />
            <label className="regInput__file">
                    <p>Изображение</p>
            </label>
                <input
                        type="file"
                        {...register('file')}
                        accept='image/jpg, image/jpeg, image/png, image/webp'
                    />
            <button className='button form-btn'>Заменить</button>
        </form>
    );
};

export default UpdateBannerForm;