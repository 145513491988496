import React from 'react';
import {NewsInterface} from "../news";
import {baseUrl} from "../../../../constants/constants";

const OneNews = ({id, name, description, image_url, source_link, create_date, update_date}: NewsInterface) => {
    return (
        <div>
            <section className="news-card">
                <div className="news-desc">
                    <a
                        href={source_link}
                        className='news-source_link'
                        target='_blank'
                    >
                        <h3 className='news-title'>{name}</h3>
                    </a>
                    <p className='news-p'>{description}</p>
                </div>
                <img
                    className='news-img'
                    src={`${baseUrl}${image_url}`}
                    alt="Картинка новости"/>
            </section>
        </div>
    );
};

export default OneNews;