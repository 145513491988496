import React from 'react';
import {VideoInterface} from "../video";

const OneVideo = ({id, name, video_url, create_date, category_id, category_name}: VideoInterface) => {

    return (
            <div className='video-section'>
                <iframe
                    className='video'
                    src={video_url}
                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="0"
                    allowFullScreen>
                </iframe>
                <p className='video-text'>{name}</p>
            </div>
    );
};

export default OneVideo;