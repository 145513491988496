import React, {useEffect, useState} from 'react';
import {ForumInterface} from "../../forums";
import Modal from "../../../../../components/modal/modal";
import RegistrationForm from "../forumsRegistrationForm/registrationForm";
import {useAuth} from "../../../../../components/authProvider/authProvider";
import ModalLogin from "../../../../../components/modalLogin/modalLogin";
import {baseUrl} from "../../../../../constants/constants";


const Forum:React.FC<ForumInterface> = ({id,
                                            name,
                                            description,
                                            supplement,
                                            start_date_training,
                                            end_date_training,
                                            image_url, is_active,
                                            forum_visit_format,
                                            forum_location
}) => {
    const [showModal, setShowModal] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const isAuth = useAuth()

    const closeModal = () =>{
        setShowModal(false)
    }

    const forumStartDate = new Date(start_date_training).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    const forumStartDateShort = new Date(start_date_training).toLocaleString('ru', {
        // month: 'long',
        day: 'numeric',
        // year: 'numeric'
    });

    const forumEndDate = new Date(end_date_training).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    const openPopupWithAuth = () => {
        if (isAuth?.authState?.isAuth){
            setShowModal(true)
        } else {
            setShowLoginModal(true)
        }
    }

    const endDate = new Date(end_date_training)

    useEffect(() => {
        let now = new Date()
        if(now > endDate){
            setDisabled(true);
        } else setDisabled(false);

        if(is_active){
            setDisabled(false)
        } else setDisabled(true)
    }, []);


    return (
            <section className="forum">
                <div className="forum-img">
                    <img
                        src={`${baseUrl}${image_url}`}
                        alt="Обложка мероприятия"
                        className='forum-img__img'
                    />
                </div>
                <div className="forum-description">
                    <div className="forum-description__text">
                        <h2 className="forum-description__text-h2">
                            {name}
                        </h2>
                        <p className="forum-description__text-p">
                            {description}
                        </p>
                    </div>
                    <div className="forum-description__button">
                        <button
                            onClick={openPopupWithAuth}
                            disabled={disabled}
                            className={disabled ? 'disabledButton button' : ' button forum-btn'}
                        >
                            Регистрация
                        </button>
                        {showModal &&
                            <Modal
                                active={showModal}
                                title="Регистрация на форум"
                                сlose={closeModal}
                            >
                                <RegistrationForm
                                    id={id}
                                    close={closeModal}
                                    forum_visit_format={forum_visit_format}
                                />
                            </Modal>
                        }
                        {showLoginModal &&
                            <ModalLogin
                                showLoginModal={showLoginModal}
                                setShowLoginModal={setShowLoginModal}
                            />
                        }
                        {is_active ? (
                        <div className="forum-description__button-date">
                            <p className="forum-description__button-date-p">{forumStartDateShort} - {forumEndDate}</p>
                            <p className="forum-description__button-date-p">{forum_location}</p>
                        </div>
                        )
                            :
                            <div style={{display: 'none'}}></div>
                        }
                    </div>
                </div>
            </section>
    );
};

export default Forum;

// <RegistrationForm id={id}/>
//