import React from 'react';
import {Link, Outlet} from 'react-router-dom'
import Header from "./header/header";
import Footer from "./footer/footer";
import { ScrollOnTop } from '../shared/utils/scrollOnTop/scrollOnTop';
import {useAuth} from "./authProvider/authProvider";


const Layout = () => {
    const authContext = useAuth()

    return (
        <div className='container'>
            <ScrollOnTop/>
            <header>
                <Header/>
            </header>

            <Outlet />

            <footer>
                <Footer/>
            </footer>
        </div>
    );
};

export {Layout};