import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../constants/constants";
import DepartmentList from "./components/Department/DepartmentList/DepartmentList";
import './AboutUsSettingsPage.scss'
import Modal from "../../../../components/modal/modal";
import CreateNewDepartment from "./components/Department/CreateNewDepartment/CreateNewDepartment";
import EmployeesList from "./components/Employee/EmployeesList/EmployeesList";
import createEmployee from "./components/Employee/CreateEmployee/CreateEmployee";
import CreateEmployee from "./components/Employee/CreateEmployee/CreateEmployee";
import {successToast} from "../../../../shared/toastify";

export interface IDepartmentsSettings{
    id: number,
    name: string,
    image_url: string,
    banner_desktop_url: string,
    banner_mobile_url: string,
    priority: number,
    position_departament:[{
         id: number,
         name: string
    }]
}

export interface IEmployeeSettings{
    id: number,
    fio: string,
    image_url: string,
    interests: string,
    achievement: string,
    departament_name: string,
    position_departament_name: string,
    priority: number
}

export interface IPositionWorkSettings{
    id: number,
    name: string
}

const AboutUsSettingsPage = () => {
    const [departments, setDepartments] = useState<IDepartmentsSettings[]>([]);
    const [employees, setEmployees] = useState<IEmployeeSettings[]>([]);
    const [showDepartmentModal, setShowDepartmentModal] = useState(false)
    const [showEmployeeModal, setShowEmployeeModal] = useState(false)
    const [positionWork, setPositionWork] = useState<IPositionWorkSettings[]>([])

    useEffect(() => {
        fetch(`${baseUrl}departament`)
            .then((res) => res.json())
            .then((data) => {
                setDepartments(data)
            })

        fetch(`${baseUrl}employee`)
            .then((res) => res.json())
            .then((data) => {
                setEmployees(data)
            })

        fetch(`${baseUrl}position_departament`)
            .then((res) => res.json())
            .then((data) => {
                setPositionWork(data)
            })
    }, []);

    async function deleteCurrentDepartment(id: number){
        try{
            const res = await fetch(`${baseUrl}departament/${id}`,{
                method: 'DELETE'
            })
            successToast("Отдел удалён!")
            return res.ok
        } catch (err){
            console.log(err)
        }
    }

    async function deleteCurrentEmployee(id: number){
        try{
            const res = await fetch(`${baseUrl}employee/${id}`, {
                method: 'DELETE'
            })
            successToast("Работник удалён!")
            return res.ok
        } catch (err){
            console.log(err)
        }
    }

    const deleteDepartment = (id: number) => {
        setDepartments(departments.filter((departments) => departments.id !== id))
    }

    const deleteEmployee = (id: number) => {
        setEmployees(employees.filter((employees) => employees.id !== id))
    }

    const updateEmployeeStatus = (currentEmployee: IEmployeeSettings) => {
        deleteCurrentEmployee(currentEmployee.id)
        setEmployees([...employees, {...currentEmployee}])
        deleteEmployee(currentEmployee.id)
    }

    const updateDepartmentStatus = (currentDepartment: IDepartmentsSettings) => {
        deleteCurrentDepartment(currentDepartment.id)
        setDepartments([...departments, {...currentDepartment}])
        deleteDepartment(currentDepartment.id)
    }

    const closeModal = () => {
        setShowDepartmentModal(false);
        setShowEmployeeModal(false)
    }

    return (
        <section className="admin-section">
            <h1 className="admin-title__h1">Редактирование секции "Команда"</h1>
            <div className="admin-buttons">
                <p className="admin-title__p">Создать отдел:</p>
                <button className="button admin-btn" onClick={() => setShowDepartmentModal(true)}>Создать</button>
                {showDepartmentModal &&
                    <Modal
                        active={showDepartmentModal}
                        title='Введите информацию об отделе'
                        сlose={closeModal}
                    >
                        <CreateNewDepartment close={closeModal}/>
                    </Modal>
                }
            </div>
            <div className="admin-buttons">
                <p className="admin-title__p">Добавить сотрудника:</p>
                <button
                    className='button admin-btn'
                    onClick={() => setShowEmployeeModal(true)}
                >
                    Добавить
                </button>
                {showEmployeeModal &&
                    <Modal
                        active={showEmployeeModal}
                        title='Добавьте информацию о сотруднике'
                        сlose={closeModal}
                    >
                        <CreateEmployee
                            department={departments}
                            position={positionWork}
                            close={closeModal}
                        />
                    </Modal>
                }
            </div>


            {/*Список отделов (Departments List)*/}
            <h2 className='admin-title__h2'>Список отделов:</h2>
            <div className="admin-div">
                {departments.map((i) => (
                    <DepartmentList
                        key={i.id}
                        departmentList={i}
                        deleteDepartment={updateDepartmentStatus}
                    />
                ))}
            </div>

            {/*Список работников (EmployeesList)*/}
            <div className="one-table">
                <h2 className='admin-title__h2'>Список сотрудников:</h2>
                <table className="applications-table">
                    <thead className='applications-table__thead'>
                        <tr>
                            <th className="applications-table__thead-th">Фото</th>
                            <th className="applications-table__thead-th">ФИО</th>
                            <th className="applications-table__thead-th">Отдел</th>
                            <th className="applications-table__thead-th">Должность</th>
                            {/*<th className="applications-table__thead-th">Интересы</th>*/}
                            {/*<th className="applications-table__thead-th">Достижения</th>*/}
                            <th className="applications-table__thead-th">Приоритет</th>
                            <th className="applications-table__thead-th">Действия</th>
                        </tr>
                    </thead>
                    {employees && employees.map((i) => (
                        <tbody
                            className="applications-table__tbody"
                            key={i.id}
                        >
                        <EmployeesList
                            employeesList={i}
                            department={departments}
                            position={positionWork}
                            deleteEmployee={updateEmployeeStatus}
                        />
                        </tbody>
                    ))}
                </table>
            </div>
        </section>
    );
};

export default AboutUsSettingsPage;