import React from 'react';
import {Link} from "react-router-dom";
import './adminNavPanel.scss'

const AdminNavPanel = () => {
    return (
        <>
            <nav className='admin-page__nav'>
                <ul className='admin-page__nav-list'>
                    <li className='admin-page__nav-list__element'>
                        <Link to='admin-panel'>Новости</Link>
                    </li>
                    <li className='admin-page__nav-list__element'>
                        <Link to='admin-panel/applications'>Заявки</Link>
                    </li>
                    <li className='admin-page__nav-list__element'>
                        <Link to='admin-panel/courses'>Курсы</Link>
                    </li>
                    <li className='admin-page__nav-list__element'>
                        <Link to='admin-panel/media'>Медиаматериалы</Link>
                    </li>
                    <li className='admin-page__nav-list__element'>
                        <Link to='admin-panel/forums'>Мероприятия</Link>
                    </li>
                    <li className='admin-page__nav-list__element'>
                        <Link to='admin-panel/team'>Команда</Link>
                    </li>
                    <li className='admin-page__nav-list__element'>
                        <Link to='admin-panel/obzor'>Обзор.НЦПТИ</Link>
                    </li>
                    <li className='admin-page__nav-list__element'>
                        <Link to='admin-panel/users'>Пользователи</Link>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default AdminNavPanel;