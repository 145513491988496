import React, {useState} from 'react';
import {IDepartmentsSettings, IEmployeeSettings, IPositionWorkSettings} from "../../../AboutUsSettingsPage";
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../../constants/constants";
import {successToast} from "../../../../../../../shared/toastify";

interface INewInfoEmployee{
    fio: string,
    image: string,
    interests: string,
    achievement: string,
    departament_id: string,
    position_departament_id: string,
    priority: string
}

interface ICurrentInoEmployee{
    employee: IEmployeeSettings,
    department: IDepartmentsSettings[],
    position: IPositionWorkSettings[],
    close: () => void
}

const UpdateEmployee:React.FC<ICurrentInoEmployee> = ({employee, department, position, close}) => {
    const {
        register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<INewInfoEmployee>({
        defaultValues:{
            fio: employee.fio,
            interests: employee.interests,
            achievement: employee.achievement,
            departament_id: department.find(d => d.name === employee.departament_name)?.id.toString() || department[0].id.toString() || '',
            position_departament_id: position.find(e => e.name === employee.position_departament_name)?.id.toString() || position[0].id.toString() || '',
            priority: String(employee.priority)
        }
    })

    const [values, setValues] = useState(department[0].id || '')
    const [positionValues, setPositionValues] = useState(position[0].id || '')

    const submit: SubmitHandler<INewInfoEmployee> = async (data: any) => {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'image'){
                    if (!!data[key][0]){
                        formData.append(key, data[key][0])
                    }
                } else {formData.append(key, data[key])}
            }
            console.log('hui')
            const res = await fetch(`${baseUrl}employee/${employee.id}`, {
                method: 'PUT',
                body: formData
            })
            if (res.status === 200){
                successToast('Информация о сотруднике обновлена! Пожалуйста, обновите страницу')
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message:''})
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <input
                type="text"
                className="regInput"
                placeholder='Имя сотрудника'
                {...register('fio')}
            />
            <textarea
                className='regInput'
                placeholder='Интересы'
                {...register('interests')}
            />
            <textarea
                className='regInput'
                placeholder='Достижения'
                {...register('achievement')}
            />
            <label
                className="regLabel"
            >
                Отдел:
                <select
                    id='department-id__employee'
                    value={values}
                    {...register('departament_id', {required: true})}
                    onChange={(e) => setValues(e.target.value)}
                >
                    {department.map((i) => <option value={i.id}>{i.name}</option>)}
                </select>
            </label>
            <label
                className="regLabel"
            >
                Должность:
                <select
                    id='position-id__employee'
                    value={positionValues}
                    {...register('position_departament_id', {required: true})}
                    onChange={(e) => setPositionValues(e.target.value)}
                >
                    {position.map((i) => <option value={i.id}>{i.name}</option>)}
                </select>
            </label>
            <span className='regInput__file'>
                <p>Выберите фото сотрудника</p>
                <input
                    type="file"
                    className="regInput"
                    {...register('image')}
                    accept='image/jpg, image/jpeg, image/webp, image/pdf'
                />
            </span>
            <input
                type="number"
                className="regInput"
                placeholder='Приоритет'
                {...register('priority')}
            />
            <button className="button form-btn">Обновить</button>
        </form>
    );
};

export default UpdateEmployee;