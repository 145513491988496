import React, {PropsWithChildren, useEffect} from 'react';
import './modal.scss'

interface Props{
    active: boolean,
    title: string,
    сlose: () => void,
    subtitle?: string
}
const Modal = ({active, title, сlose, subtitle, children}: PropsWithChildren<Props>) => {


    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'unset'

        }
    }, []);

    return (
        <>
            {
                active ? <div className='modal' onClick={сlose}>
            <div className="modal__content" onClick={e => e.stopPropagation()}>
                <div className="modal__header">
                    <div className="modalTitle">
                        <p className="modal-close__cross" onClick={сlose}>&times;</p>
                        <h2>{title}</h2>
                    </div>
                    {subtitle &&
                        <div className="modalTitle">
                            <h2>{subtitle}</h2>
                        </div>
                    }
                </div>
                <div className="modal__body">
                    {children}
                </div>
            </div>
        </div>
                    :
                    <>
                    </>
            }
            </>
    );
};

export default Modal;