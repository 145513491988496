import React, {useState} from 'react';
import {IDepartmentsSettings, IPositionWorkSettings} from "../../../AboutUsSettingsPage";
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../../constants/constants";
import {successToast} from "../../../../../../../shared/toastify";

interface ICreateEmployee{
    fio: string,
    image: string,
    interests: string,
    achievement: string,
    departament_id: string,
    position_departament_id: string,
    priority: number
}

interface Props{
    department: IDepartmentsSettings[],
    position: IPositionWorkSettings[],
    close: () => void
}

const CreateEmployee:React.FC<Props> = ({department, position, close}) => {
    const [values, setValues] = useState('')
    const [positionValues, setPositionValues] = useState('')
    const{
        register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<ICreateEmployee>()

    const submit: SubmitHandler<ICreateEmployee> = async(data: any)=> {
        try{
            const formData = new FormData()
            for (let key in data){
                if(key == 'image'){
                    formData.append(key, data[key][0])
                } else formData.append(key, data[key])
            }
            const res = await fetch(`${baseUrl}employee`, {
                method: 'POST',
                body: formData
            })
            if(res.status === 200){
                successToast('Работник добавлен. Пожалуйста, обновите страницу');
                close()
            } else if(res.status === 401){
                setError('root', {type: "custom", message:""})
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <input
                type="text"
                className="regInput"
                placeholder='ФИО работника'
                {...register('fio', {
                    required: true
                })}
            />
            {errors.fio && <p className='wrong-message'>Это поле обязательно</p>}
            <textarea
                className='regInput'
                placeholder='Интересы'
                {...register('interests')}
            />
            <textarea
                className='regInput'
                placeholder='Достижения'
                {...register('achievement')}
            />
            <label
                className="regLabel"
            >
                Отдел:
                <select
                    id='department-id__employee'
                    value={values}
                    {...register('departament_id', {required: true})}
                    onChange={(e) => setValues(e.target.value)}
                >
                    {department.map((i) => <option value={i.id}>{i.name}</option>)}
                </select>
            </label>
            {errors.departament_id && <p className='wrong-message'>Это поле обязательно</p>}
            <label
                className="regLabel"
            >
                Должность:
                <select
                    id='position-id__employee'
                    value={positionValues}
                    {...register('position_departament_id', {required: true})}
                    onChange={(e) => setPositionValues(e.target.value)}
                >
                    {position.map((item) => <option value={item.id}>{item.name}</option>)}
                </select>
            </label>
            {errors.position_departament_id && <p className='wrong-message'>Это поле обязательно</p>}
            <span className='regInput__file'>
                <p>Выберите фото сотрудника</p>
                <input
                    type="file"
                    className="regInput"
                    {...register('image')}
                    accept='image/jpg, image/jpeg, image/webp, image/pdf'
                />
            </span>
            <input
                type="number"
                className="regInput"
                placeholder='Приоритет'
                {...register('priority')}
            />
            <button className="button form-btn">Создать</button>
        </form>
    );
};

export default CreateEmployee;