import React from 'react';
import {IUsersSettings} from "../../UsersSettingPage";

interface IUsersList {
    usersList: IUsersSettings,
    deleteUser: (currentUser: IUsersSettings) => void
}


const UsersList:React.FC<IUsersList> = ({usersList, deleteUser}) => {

    const date_joined = new Date(usersList.date_joined).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <>
            <tr>
                {/*<td className="applications-table__tbody-td">{usersList.is_active ? 'Да' : 'Нет'}</td>*/}
                <td className="applications-table__tbody-td">{usersList.full_name}</td>
                <td className="applications-table__tbody-td">{usersList.male ? 'Мужской' : 'Женский'}</td>
                <td className="applications-table__tbody-td">{usersList.age}</td>
                <td className="applications-table__tbody-td">{usersList.place_of_work}</td>
                <td className="applications-table__tbody-td">{usersList.position_work}</td>
                <td className="applications-table__tbody-td">{usersList.number_phone}</td>
                <td className="applications-table__tbody-td">{usersList.telegram_link}</td>
                <td className="applications-table__tbody-td">{usersList.about_yourself}</td>
                <td className="applications-table__tbody-td">{usersList.email}</td>
                <td className="applications-table__tbody-td">{usersList.email_subscription ? 'Есть' : 'Нет'}</td>
                <td className="applications-table__tbody-td">{usersList.is_admin ? 'Да' : 'Нет'}</td>
                <td className="applications-table__tbody-td">{date_joined}</td>
                <td className="applications-table__tbody-td">
                    <button
                        className="button updateBtn"
                        onClick={() => deleteUser(usersList)}
                    >
                        Забанить
                    </button>
                </td>
            </tr>
        </>
    );
};

export default UsersList;