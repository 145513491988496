import React, {PropsWithChildren, useEffect} from 'react';
import './ModalEmployee.scss'

interface IEmployeeModal {
    active: boolean,
    close: () => void
}

const ModalEmployee = ({active, close, children}: PropsWithChildren<IEmployeeModal>) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'unset'

        }
    }, []);

    return (
        <>
            {active ? <div className='employee-modal' onClick={close}>
                    <div className="employee-modal__body">
                        <p className="employee-modal__close-cross" onClick={close}>&times;</p>
                        {children}
                    </div>
                </div>
                :
                <>
                </>
            }
        </>
    );
};

export default ModalEmployee;