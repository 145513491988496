import React from 'react';
import {IDepartmentsSettings} from "../../../AboutUsSettingsPage";
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../../constants/constants";
import {successToast} from "../../../../../../../shared/toastify";

interface INewInfoDepartment{
    name: string,
    image: string,
    banner_desktop: string,
    banner_mobile: string,
    priority: number
}

interface ICurrentDepartment{
    department: IDepartmentsSettings,
    close: () => void
}

const UpdateDepartment:React.FC<ICurrentDepartment> = ({department, close}) => {
    const{
        register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<INewInfoDepartment>({
        defaultValues:{
            name: department.name,
            priority: department.priority
        }
    })

    const handleChange = (event: {target: {files: any}; }) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<INewInfoDepartment> = async (data: any) => {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'image' || key == 'banner_desktop' || key == 'banner_mobile'){
                    if (!!data[key][0]){
                        formData.append(key, data[key][0])
                    }
                } else {formData.append(key, data[key])}
            }
            const res = await fetch (`${baseUrl}departament/${department.id}`, {
                method: 'PUT',
                body: formData
            })
            if (res.status === 200){
                successToast('Информация об отделе обновлена! Пожалуйста, обновите страницу')
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message: ''})
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <input
                type="text"
                className="regInput"
                placeholder='Название отдела'
                {...register('name')}
            />
            <span className="regInput__file">
                    <p>Изображение отдела</p>
                    <input
                        type="file"
                        {...register('image')}
                        accept='image/jpg, image/jpeg,image/webp, image/png'
                    />
            </span>

            <span className="regInput__file">
                    <p>Баннер отдела</p>
                    <input
                        type="file"
                        {...register('banner_desktop')}
                        accept='image/jpg, image/jpeg,image/webp, image/png'
                    />
            </span>

            <span className="regInput__file">
                    <p>Баннер отдела (мобильный)</p>
                    <input
                        type="file"
                        {...register('banner_mobile')}
                        accept='image/jpg, image/jpeg,image/webp, image/png'
                    />
            </span>

            <input
                type="number"
                className='regInput'
                placeholder='Приоритет'
                {...register('priority')}
            />
            <button className="button form-btn">Обновить</button>
        </form>
    );
};

export default UpdateDepartment;