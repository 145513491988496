import React from 'react';
import {JournalInterface} from "../catalog";
import {baseUrl} from "../../../../../../constants/constants";

const OneJournal = ({id, name, file_url, file_card_url}: JournalInterface) => {
    console.log(baseUrl)
    return (
        <div className='oneJournal'>
            <a href={`${baseUrl}${file_url}`} target='_blank' download={false}>
                <img
                    className='journal-image'
                    src={`${baseUrl}${file_card_url}`}
                    alt="Выпуск журнала Обзор.НЦПТИ"/>
            </a>
        </div>
    );
};

export default OneJournal;