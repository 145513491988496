import React, {useState} from 'react';
import {IDepartmentsSettings, IEmployeeSettings, IPositionWorkSettings} from "../../../AboutUsSettingsPage";
import {baseUrl} from "../../../../../../../constants/constants";
import Modal from "../../../../../../../components/modal/modal";
import UpdateDepartment from "../../Department/UpdateDepartment/UpdateDepartment";
import UpdateEmployee from "../UpdateEmployee/UpdateEmployee";

interface IEmployeesList{
    employeesList: IEmployeeSettings;
    department: IDepartmentsSettings[],
    position: IPositionWorkSettings[],
    deleteEmployee: (currentEmployee: IEmployeeSettings) => void
}

const EmployeesList:React.FC<IEmployeesList> = ({employeesList, department, position, deleteEmployee}) => {
    const [showUpdateEmployeeModal, setShowUpdateEmployeeModal] = useState(false)

    const closeModal = () => {
        setShowUpdateEmployeeModal(false)
    }

    return (
        <tr>
            <td className="applications-table__tbody-td">
                <img
                    src={`${baseUrl}${employeesList.image_url}`}
                    alt="Фото сотрудника"
                    className="cards-settings__img"
                />
            </td>
            <td className="applications-table__tbody-td">{employeesList.fio}</td>
            <td className="applications-table__tbody-td">{employeesList.departament_name}</td>
            <td className="applications-table__tbody-td">{employeesList.position_departament_name}</td>
            {/*<td className="applications-table__tbody-td">{employeesList.interests}</td>*/}
            {/*<td className="applications-table__tbody-td">{employeesList.achievement}</td>*/}
            <td className="applications-table__tbody-td">{employeesList.priority}</td>
            <td className="applications-table__tbody-td">
                <button
                    className="button updateBtn"
                    onClick={() => setShowUpdateEmployeeModal(true)}
                >
                    Обновить
                </button>
                {showUpdateEmployeeModal &&
                    <Modal
                        active={showUpdateEmployeeModal}
                        title='Обновите информацию о сотруднике'
                        сlose={closeModal}
                    >
                        <UpdateEmployee
                            employee={employeesList}
                            department={department}
                            position={position}
                            close={closeModal}
                        />
                    </Modal>
                }
                <button
                    className="button updateBtn"
                    onClick={() => deleteEmployee(employeesList)}
                >
                    Удалить
                </button>
            </td>
        </tr>
    );
};

export default EmployeesList;