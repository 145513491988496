import React, {useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {ICategoryManuals} from "../ManualsSettings";
import {baseUrl} from "../../../../../../../constants/constants";
import {errorToast, successToast} from "../../../../../../../shared/toastify";

interface ICreateManual{
    name: string,
    file: string,
    file_card: string,
    category_id: number
}

interface Props{
    category: ICategoryManuals[]
    close: () => void
}

const CreateNewManual = ({category, close}: Props) => {
    const [values, setValues] = useState('')
    const{register,
        handleSubmit,
        formState:{errors},
        setError} = useForm<ICreateManual>()

    const handleChange = (event: {target: {files: any}; }) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<ICreateManual> = async(data:any)=> {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key === 'file' || key == 'file_card'){
                    formData.append(key, data[key][0])
                } else {formData.append(key, data[key])}
            }
            const res = await fetch(`${baseUrl}manuals`, {
                method: "POST",
                body: formData
            });
            if (res.status === 200){
                successToast('Методичка опубликована');
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <input
                type="text"
                className="regInput"
                placeholder='Заголовок методички'
                {...register('name',
                    {required: true})}
            />
            {errors.name && <p className='wrong-message'>Это поле обязательно</p>}

            <label
                className="regLabel"
            >
                Категория:
                <select
                    id='category-manuals'
                    value={values}
                    {...register('category_id', {required: true})}
                    onChange={(e) => setValues(e.target.value)}
                >
                    {category.map((i) => <option value={i.id}>{i.name}</option>)}
                </select>
            </label>
            {errors.category_id && <p className='wrong-message'>Это поле обязательно</p>}

            <span className="regInput__file">
                    <p>Выберите файл методички</p>
                    <input
                        type="file"
                        {...register('file', {required: true})}
                        accept='application/msword, application/pdf'
                    />
            </span>
            {errors.file && <p className='wrong-message'>Это поле обязательно</p>}

            <span className="regInput__file">
                    <p>Изображение методички</p>
                    <input
                        type="file"
                        {...register('file_card', {required: true})}
                        accept='image/jpg, image/jpeg, image/png, image/webp'
                    />
                </span>
            {errors.file_card && <p className='wrong-message'>Это поле обязательно</p>}

            <button className='button form-btn'>Опубликовать</button>
        </form>
    );
};

export default CreateNewManual;