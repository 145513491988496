import React, {useState} from 'react';
import {EmployeeInterface} from "../../department";
import {useParams} from "react-router-dom";
import './EmployeeListHeader.scss'
import BigEmployeeCard from "./BigEmployeeCard/BigEmployeeCard";
import EmployeesList
    from "../../../../../../Admin/Pages/AboutUsSettingsPage/components/Employee/EmployeesList/EmployeesList";
import EmployeeCard from "../EmployeeCard";


interface IEmployeeHeader{
    employees: EmployeeInterface[]
}

const EmployeeListHeader:React.FC<IEmployeeHeader> = ({employees}) => {
    const [showModal, setShowModal] = useState(false);
    const {id} = useParams();
    const { id: employeeId, fio, image_url, interests, department_name, achievement, position_departament_name, priority} =  employees[0]


    const closeModal = () => {
        setShowModal(false);
    };

    return (
      <div className='employee-header__main'>
            <section className="employee-boss">
                <BigEmployeeCard
                id={employeeId}
                fio={fio}
                image_url={image_url}
                interests={interests}
                department_name={department_name}
                achievement={achievement}
                position_departament_name={position_departament_name}
                priority={priority}
                className='employee-big__card'/>
            </section>
          <section className='employee-card__list'>
              {employees.slice(1).map((i) => (
                  <EmployeeCard
                      key={i.id}{...i}
                      className='employee-card__aside'
                  />
              ))}
          </section>
      </div>
    );
};

export default EmployeeListHeader;