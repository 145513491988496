import React from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../constants/constants";
import {errorToast, successToast} from "../../../../shared/toastify";

export interface IPublishNews{
    name: string,
    description: string,
    source_link: string,
    image: string
}

interface Props{
    close: () => void
}

const PublishNewsForm = ({close}: Props) => {
    const{register,
        handleSubmit,
        formState:{errors},
        setError} = useForm<IPublishNews>()

    const handleChange = (event: {target: {files: any}; }) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<IPublishNews> = async(data:any)=> {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'image'){
                    formData.append(key, data[key][0])
                } else {formData.append(key, data[key])}
            }
            const res = await fetch(`${baseUrl}news`, {
                method: "POST",
                body: formData
            });
            if (res.status === 200){
                successToast('Новость опубликована');
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
            <form
                className='regForm'
                onSubmit={handleSubmit(submit)}
            >
                <input
                    type="text"
                    className="regInput"
                    placeholder='Заголовок новости'
                    {...register('name',
                        {required: true})}
                />
                {errors.name && <p className='wrong-message'>Это поле обязательно</p>}

                <textarea
                    className="regInput"
                    placeholder='Текст новости (если его нет - нажми пробел)'
                    {...register('description',
                        {required: true})}
                />
                <input
                    type="text"
                    className="regInput"
                    placeholder='Ссылка на новость'
                    {...register('source_link',
                        {required: true})}
                />
                {errors.source_link && <p className='wrong-message'>Это поле обязательно</p>}

                <span className="regInput__file">
                    <p>Изображение записи</p>
                    <input
                        type="file"
                        {...register('image', {required: true})}
                        accept='image/jpg, image/jpeg, image/webp, image/png'
                    />
                </span>
                {errors.image && <p className='wrong-message'>Это поле обязательно</p>}

                <button className='button form-btn'>Опубликовать</button>
            </form>
    );
};

export default PublishNewsForm;