import React from 'react';

const DetectionOfIllegalContent = () => {
    return (
        <form
            className='forum-modal'
        >
            <h3 className='forum-modal__h'>Объем – 16 академических часов.</h3>
            <p className="forum-modal__p">Освоение программы предполагает получение актуальных знаний в сфере законодательства по противодействию терроризму и экстремизму, мониторинга информационного пространства, методик выявления противоправного контента,
                формирования киберволонтерских организаций, а также реализации профилактической работы среди молодежи с помощью новых медиа.</p>
        </form>
    );
};

export default DetectionOfIllegalContent;