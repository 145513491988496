import React from 'react';
import {set, SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../constants/constants";
import {errorToast, successToast} from "../../../../../../shared/toastify";

interface INewJournal{
    name: string,
    file: string,
    file_card: string,
    date_publication: Date
}

interface Props {
    close: () => void
}

const CreateJournal = ({close}: Props) => {
    const{
        register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<INewJournal>()

    const submit: SubmitHandler<INewJournal> = async (data: any) => {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'file' || key == 'file_card'){
                    formData.append(key, data[key][0])
                } else {
                    formData.append(key, data[key])
                }
            }
            const res = await fetch(`${baseUrl}journals`, {
                method:'POST',
                body: formData
            })
            if(res.status === 200){
                successToast('Журнал опубликовн. Пожалуйста, обновите страницу');
                close()
            } else if(res.status === 401){
                setError('root', {type:'custom', message:''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <input
                type="text"
                className="regInput"
                placeholder='Название журнала'
                {...register('name', {required: true})}
            />
            {errors.name && <p className='wrong-message'>Это поле обязательно</p>}

            <span className="regInput__file">
                <p>Файл журнала</p>
                <input
                    type="file"
                    {...register('file', {required: true})}
                    accept='application/pdf'
                />
            </span>
            {errors.file && <p className='wrong-message'>Загрузите файл в формате .pdf</p>}

            <span className="regInput__file">
                <p>Изображение журнала</p>
                <input
                    type="file"
                    {...register('file_card', {required: true})}
                    accept='image/jpg, image/jpeg, image/webp, image/png'
                />
                {errors.file_card && <p className='wrong-message'>Загрузите картинку формата jpg, png, webp</p>}

                <label className='regLabel'>
                    Дата публикации
                    <input
                        type="date"
                        className="regInput"
                        {...register('date_publication')}
                    />
                </label>

                <button className="button form-btn">Добавить</button>
            </span>
        </form>
    );
};

export default CreateJournal;