import React from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../constants/constants";
import {errorToast, successToast} from "../../../../../../shared/toastify";

interface INewOnlineCourse{
    name: string,
    description: string,
    price: number,
    image: string,
    is_active: boolean
}

interface Props{
    close: () => void
}

const CreateOnlineCourse = ({close}: Props) => {
    const {register,
        handleSubmit,
        formState: {errors},
        setError} = useForm<INewOnlineCourse>()

    const handleChange = (event:{target:{files: any};}) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<INewOnlineCourse> = async (data: any)=> {
        try{
            const formData = new FormData();
            for(let key in data){
                if(key === 'image'){
                    formData.append(key, data[key][0])
                } else formData.append(key, data[key])
            }
            const res = await fetch(`${baseUrl}online_course`, {
                method: 'POST',
                body: formData
            })
            if(res.status === 200){
                successToast('Новый онлайн курс добавлен');
                close()
            } else if(res.status === 401){
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <input
                type="text"
                className="regInput"
                placeholder='Название курса'
                {...register('name', {required: true})}
            />
            {errors.name && <p className='wrong-message'>Это поле обязательно</p>}
            <textarea
                className="regInput"
                placeholder="Описание"
                {...register('description', {required: true})}
            />
            {errors.description && <p className='wrong-message'>Это поле обязательно</p>}
            <input
                type="number"
                className="regInput"
                placeholder="Цена"
                {...register('price', {required: true})}
            />
            {errors.price && <p className='wrong-message'>Это поле обязательно</p>}
            <span className="regInput__file">
                <p>Изображение курса</p>
                <input
                    type="file"
                    {...register('image')}
                    accept='image/jpg, image/jpeg, image/webp, image/png'
                />
            </span>
            <button className="button form-btn">Добавить</button>
        </form>
    );
};

export default CreateOnlineCourse;