import React from 'react';
import {IBlankOnlineCourse} from "../../ApplicationsPage";

const BlankOnline = ({
    email,
    full_name,
    full_name_declination,
    age,
    number_phone,
    place_of_work,
    position_work,
    higher_education,
    buy_certificate,
    online_course,
    date_creation
                     }:IBlankOnlineCourse) => {

    const date_blank_online = new Date(date_creation).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <tr>
            <td className='applications-table__tbody-td'>{online_course.name}</td>
            <td className='applications-table__tbody-td'>{email}</td>
            <td className='applications-table__tbody-td'>{full_name}</td>
            <td className='applications-table__tbody-td'>{full_name_declination}</td>
            <td className='applications-table__tbody-td'>{age}</td>
            <td className='applications-table__tbody-td'>{number_phone}</td>
            <td className='applications-table__tbody-td'>{place_of_work}</td>
            <td className='applications-table__tbody-td'>{position_work}</td>
            <td className='applications-table__tbody-td'>{higher_education ? 'Да' : 'Нет'}</td>
            <td className='applications-table__tbody-td'>{buy_certificate ? 'Да' : 'Нет'}</td>
            <td className='applications-table__tbody-td'>{date_blank_online}</td>
        </tr>
    );
};

export default BlankOnline;