import React, {useEffect, useState} from 'react';
import {baseUrl} from "../../../../../constants/constants";
import OneCard from "./oneCard";
import '../cards.scss'

export interface CardsInterface {
    id: number,
    name: string,
    file_url: string,
    file_card_url: string,
    type_file: string,
    file_extension: string,
    create_date: Date,
    category_id: number,
    category_name: string
}
interface Props{
    id: number,
    name: string
}

const CardsRouter = ({id, name}: Props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8
    const [cards, setCards] = useState<CardsInterface[]>([])
    const [paginationDisabled, setPaginationDisabled] = useState(false)


    useEffect(() => {
        fetch(`${baseUrl}memos?page=${currentPage}&page_size=${pageSize}&sort_date=true&category_id=${id}`)
            .then((res) => res.json())
            .then((data) => {
                setCards(data)
            })
    }, [currentPage]);

    useEffect(() => {
        fetch(`${baseUrl}memos?page=${currentPage+1}&page_size=${pageSize}&sort_date=true&category_id=${id}`)
            .then((res) => res.json())
            .then((data) => {
                if (data.length === 0){
                    setPaginationDisabled(true)
                } else {
                    setPaginationDisabled(false)
                }
            })
    }, [currentPage]);

    return (
        <div className='cards-router'>
            <h2 className='cards-router__h2'>{name}</h2>
            <div className="allCards">
                {cards && cards.length > 0 ?
                    cards.map((item) =>
                        <OneCard key={item.id}{...item}/>
                    )
                    :
                    <h2 className='notFound-title'>Карточки не найдены</h2>
                }
            </div>
            <div className="router-btns">
                <button
                    className={currentPage === 1 ? 'disabledButton btn-arrow button' : 'btn-arrow button'}
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                >
                    ← Назад
                </button>
                <button
                    className={paginationDisabled ? 'disabledButton btn-arrow button' : 'btn-arrow button'}
                    disabled={paginationDisabled}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    Вперёд →
                </button>
            </div>
        </div>
    );
};

export default CardsRouter;