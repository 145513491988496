import React from 'react';
import {SborniksInterface} from "../sborniks";
import {baseUrl} from "../../../../../../../constants/constants";

const Sbornik = ({id, name, file_url, file_card_url, type_file, file_extension, create_date, category_id, category_name}: SborniksInterface) => {
    return (
        <>
            <div className="one-sbornik">
                <a href={`${baseUrl}${file_url}`} target='_blank'>
                    <img src={`${baseUrl}${file_card_url}`} alt="Обложка методических рекомендаций" className='manuals-image'/>
                    <p className='manuals-description'>{name}</p>
                </a>
            </div>
        </>
    );
};

export default Sbornik;