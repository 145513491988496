import React, { useEffect, useState } from "react";
import "./account.scss";
import { baseUrl } from "../../../constants/constants";
import AccountMe from "./components/account_me/account_me";
import Modal from "../../../components/modal/modal";
import AccountModal from "./components/account_modal/account_modal";
import {Link, useNavigate} from "react-router-dom";
import { useAuth } from "../../../components/authProvider/authProvider";

export interface AccountInterface {
    full_name: string;
    full_name_declination: string;
    male: boolean;
    age: number;
    email: string;
    email_subscription: boolean;
    place_of_work: string;
    position_work: string;
    number_phone: string;
    telegram_link: string;
    about_yourself: string;
    date_joined: Date;
    is_admin: boolean;
    close: () => void;
}

const Account = () => {
    const [userData, setUserData] = useState<AccountInterface>();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const isAuth = useAuth();

    const closeModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        fetch(`${baseUrl}user/me`)
            .then((res) => res.json())
            .then((data) => {
                setUserData(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div className="page">
            <h1 className="title-h1">Ваши данные</h1>
            <section className="account-user">
                {isAuth?.authState.user && (
                    <AccountMe/>
                )}
                <div className="account-user__btns">
                <button
                    onClick={() => setShowModal(true)}
                    className="button account-user__btn"
                >
                    Изменить
                </button>
                <button
                    onClick={() => isAuth?.logOut()}
                    className="button account-user__btn"
                >
                    Выйти
                </button>
                    {isAuth?.authState.user?.is_admin &&
                        <Link to='/admin-panel'>
                            <button
                                className=" button account-user__btn"
                            >
                                Админ-панель
                            </button>
                        </Link>
                    }
                </div>
            </section>

            {showModal && !!userData && (
                <Modal
                    active={showModal}
                    title={"Измените свои данные"}
                    сlose={closeModal}
                >
                    <AccountModal close={closeModal}/>
                </Modal>
            )}
        </div>
    );
};

export default Account;