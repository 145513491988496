import React, {useEffect, useState} from 'react';
import './news.scss'
import {baseUrl} from "../../../constants/constants";
import OneNews from "./components/oneNews";

export interface NewsInterface{
    id: number,
    name: string,
    description: string,
    source_link: string,
    image_url: string,
    create_date: Date,
    update_date: Date
}

export interface ImageTitleInterface {
    id: number,
    category_name: string,
    file_url: string,
    category_id: number
}

const News = () => {
    const [news, setNews] = useState<NewsInterface[]>([]);
    const [image, setImage] = useState<ImageTitleInterface[]>([])

    useEffect(() => {
        fetch(`${baseUrl}news?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setNews(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    useEffect(() => {
        fetch(`${baseUrl}gallery_images?category_id=16`)
            .then((res) => res.json())
            .then((data) => {
                setImage(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);


    return (
        <div>
            <section>
                {image.map((i) => (
                    <section className='title-image' key={i.id}>
                        <img
                            src={`${baseUrl}${i.file_url}`}
                            alt="Баннер новостей"
                            className='title-image__img-news'
                        />
                    </section>
                ))}
                <div className="title-mobile">
                    <h3 className='title-mobile__h3'>События недели</h3>
                </div>
            </section>
            <div className="page">
                {news && news.length > 0 ?
                    news.map((item) =>
                        <OneNews key={item.id} {...item}/>
                    )
                    :
                    <h2 className='notFound-title'>Новостей пока нет</h2>
                }
            </div>
        </div>
    );
};

export default News;