import React from 'react';
import {IPublishNews} from "../PublishNews/PublishNewsForm";
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../constants/constants";
import {INews} from "../../adminPage";
import {errorToast, successToast} from "../../../../shared/toastify";

interface IUNews{
    name: string,
    description: string,
    source_link: string,
    image: string
}
interface IUpdateNews{
    news: INews;
    close: () => void
}

const UpdateNewsForm = ({news, close}: IUpdateNews) => {
    const{register,
        handleSubmit,
        formState:{errors},
        setError} = useForm<IUNews>()

    const handleChange = (event: {target: {files: any}; }) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<IUNews> = async (data: any) => {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'image'){
                    if (!!data[key][0]){
                    formData.append(key, data[key][0])
                }
                } else {formData.append(key, data[key])}
            }
            const res = await  fetch(`${baseUrl}news/${news.id}`, {
                method: "PUT",
                body: formData
            })
            if (res.status === 200){
                successToast('Новость обновлена')
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <textarea
                placeholder='Название новости'
                className="regInput"
                defaultValue={news.name}
                {...register('name')}
            />
            <textarea
                className='regInput'
                placeholder='Содержание новости'
                defaultValue={news.description}
                {...register('description')}
            />
            <input
                type="text"
                className="regInput"
                placeholder='Ссылка'
                defaultValue={news.source_link}
                {...register('source_link')}
            />
            <span className="regInput__file">
                    <p>Изображение записи</p>
                    <input
                        type="file"
                        {...register('image')}
                        accept='image/jpg, image/jpeg, image/png, image/webp'
                    />
            </span>
            <button className='button form-btn'>Обновить</button>
        </form>
    );
};

export default UpdateNewsForm;