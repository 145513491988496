import React from 'react';
import {IOnlineCourseSettings} from "../../CoursesSettingsPage";
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../constants/constants";
import {errorToast, successToast} from "../../../../../../shared/toastify";

interface INewInfoCourse{
    name: string,
    description: string,
    price: number;
    image: string,
    is_active: boolean,
    is_archive: boolean
}

interface ICurrentCourse{
    course: IOnlineCourseSettings;
    close:() => void
}

const UpdateOnlineCourse: React.FC<ICurrentCourse> = ({course, close}) => {
    const{register,
        handleSubmit,
        formState:{errors},
        setError} = useForm<INewInfoCourse>({
        defaultValues:{
            name: course.name,
            description: course.description,
            price: course.price
        }
    })

    const handleChange = (event: {target: {files: any}; }) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<INewInfoCourse> = async (data: any) => {
        try{
            const formData = new FormData()
            for(let key in data){
                if(key == 'image'){
                    if (!!data[key][0]){
                        formData.append(key, data[key][0])
                    }
                } else {formData.append(key, data[key])}
            }
            const res = await  fetch(`${baseUrl}online_course/${course.id}`, {
                method: "PUT",
                body: formData
            })
            if (res.status === 200){
                successToast('Инофрмация о курсе обновлена. Пожаулйста, обновите страницу!')
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <textarea
                className="regInput"
                {...register('name')}
            />
            <textarea
                className="regInput"
                {...register('description')}
            />
            <input
                className="regInput"
                type='number'
                {...register('price')}
            />
            <span className="regInput__file">
                <p>Изображение курса</p>
                <input
                    type="file"
                    {...register('image')}
                    accept='image/jpg, image/jpeg, image/webp, image/png'
                />
            </span>
            <button className='button form-btn'>Обновить</button>
        </form>
    );
};

export default UpdateOnlineCourse;