import React from 'react';
import './forums.scss';
import {useState, useEffect} from "react";
import {baseUrl} from "../../../constants/constants";
import Forum from "./components/forum/forum";
import {useAuth} from "../../../components/authProvider/authProvider";

export interface ForumInterface{
    id: number,
    name: string,
    description: string,
    supplement: string,
    forum_visit_format:[
        {
            id: number,
            name: string
        }
    ],
    forum_location: string,
    start_date_training: Date,
    end_date_training: Date,
    is_active: boolean,
    image_url: string
}

interface IBanner{
    id: number;
    name: string,
    file_url: string
}

const Forums = () => {
    const [forums, setForums] = useState<ForumInterface[]>([])
    const authContext = useAuth()
    const [banner, setBanner] = useState<IBanner[]>([])
    const [mobileBanner, setMobileBanner] = useState<IBanner[]>([])

    useEffect(() => {
        fetch(`${baseUrl}gallery_images?category_id=19`)
            .then((res) => res.json())
            .then((data: IBanner[]) => {
                const banner = data.filter((item) => item.id === 74)
                setBanner(banner)
                console.log(banner)
            })

        fetch(`${baseUrl}gallery_images?category_id=19`)
            .then((res) => res.json())
            .then((data: IBanner[]) => {
                const bannerMobile = data.filter((el) => el.id === 83)
                setMobileBanner(bannerMobile)
            })
    }, []);

    useEffect(() => {
        fetch(`${baseUrl}forum?sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setForums(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const [showModal, setShowModal] = useState(false)


    const closeModal = () =>{
        setShowModal(false)
    }

    return (
        <div className='page-forums'>
            {banner && banner.map((i) => (
                <section className='title-image'>
                    <img
                        src={`${baseUrl}${i.file_url}`}
                        alt="Баннер Мероприятия"
                        loading='lazy'
                        className='title-image__img'
                    />
                </section>
            ))
            }

            {mobileBanner && mobileBanner.map((e) => (
                <section className='title-image__mobile'>
                    <img
                        src={`${baseUrl}${e.file_url}`}
                        alt="Баннер Мероприятия мобильный"
                        loading='lazy'
                        className='title-image__img-mobile'
                    />
                </section>
            ))}

            <div className="page">
                <section className="forums">
                    {forums && forums.length > 0 ?
                    forums.map((item) => <Forum key={item.id} {...item}/>
                    ) : <h2 className='notFound-title'>Мероприятий нет</h2>
                    }
                </section>
                </div>
        </div>
    );
};

export default Forums;