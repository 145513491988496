import React, {useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../constants/constants";
import {Box} from "@mui/material";
import {useAuth} from "../../../../../components/authProvider/authProvider";
import {errorToast, successToast} from "../../../../../shared/toastify";

export function checkErrorText(inputName: string, errors: any) {
    return errors?.[inputName] && errors?.[inputName]?.message;
}

interface ChangeAccountDataForm{
    full_name: string,
    full_name_declination: string,
    male: string,
    email_subscription: string,
    age: number,
    place_of_work: string,
    position_work: string,
    number_phone: string,
    telegram_link: string,
    about_yourself: string
}

interface Props{
    close: () => void
}

const AccountModal = ({close}: Props) => {
    const isAuth = useAuth()

    const{register,
        handleSubmit,
        formState:{errors},
        setError,
        control} = useForm<ChangeAccountDataForm>({
            defaultValues: {
                male: String(isAuth?.authState.user?.male),
                email_subscription: String(isAuth?.authState.user?.email_subscription)
            }
    })
    const [checked, setChecked] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked)
    }

    const submit: SubmitHandler<ChangeAccountDataForm> = async (
        data: ChangeAccountDataForm
    ) => {
        try {
            const res = await fetch(`${baseUrl}user/me/update`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    ...data,
                    male: data.male === "true"
                }),
            });
            if (res.status === 200) {
                localStorage.setItem("form", "updated");
                successToast("Информация обновлена. Пожалуйста, обновите страницу");
                close()
            } else if (res.status === 401) {
                setError("root", { type: "custom", message: "" });
                errorToast('Ошибка 401')
            }
        } catch (err) {
            console.log(err);
        }
        console.log(data)
    };

    return (
        <Box>
            <form
                onSubmit={handleSubmit(submit)}
                className='regForm'
            >
                <label className='account-label'>
                    Ваше ФИО
                    <input
                        type="text"
                        className='regInput'
                        defaultValue={isAuth?.authState.user?.full_name}
                        placeholder='Ваше ФИО'
                        {...register('full_name', {required: true})}
                    />
                    {errors.full_name && <p className="wrong-message">Это поле обязательно</p>}
                </label>
                <label className='account-label'>
                    Ваше ФИО в родительном падеже
                    <input
                        type="text"
                        className='regInput'
                        defaultValue={isAuth?.authState.user?.full_name_declination}
                        placeholder="Ваше ФИО в родительном падеже"
                        {...register('full_name_declination', {required: true})}
                    />
                    {errors.full_name_declination && <p className="wrong-message">Это поле обязательно</p>}
                </label>
                <label
                    className='account-label'
                    {...register('male', {required: true})}
                >
                    Ваш пол
                    <span>
                    <input
                        type="radio"
                        className='account-label__radio'
                        value='true'
                        {...register('male')}
                    />
                        <label className='account-label__radio__label'>Мужской</label>
                        </span>
                    <span>
                    <input
                        type="radio"
                        className='account-label__radio'
                        value='false'
                        {...register('male')}/>
                        <label className='account-label__radio__label'>Женский</label>
                        </span>
                </label>
                {errors.male && <p className='wrong-message'>Выберите пол</p>}
                <label className='account-label'>
                    Ваш возраст
                    <input
                        type="number"
                        className='regInput'
                        defaultValue={isAuth?.authState.user?.age}
                        placeholder='Ваш возраст'
                        {...register('age',
                            {required: true,
                            validate: {
                                validateAge:(v) => v > 17
                            }})}
                    />
                    {errors.age && <p className="wrong-message">Это поле обязательно</p>}
                    {errors.age && <p className='wrong-message'>Регистрация с 18 лет</p>}
                </label>
                <label className='account-label'>
                    Место работы
                    <input
                        type="text"
                        className='regInput'
                        defaultValue={isAuth?.authState.user?.place_of_work}
                        placeholder='Место работы'
                        {...register('place_of_work', {required: true})}
                    />
                    {errors.place_of_work && <p className="wrong-message">Это поле обязательно</p>}
                </label>
                <label className='account-label'>
                    Должность
                    <input
                        type="text"
                        className='regInput'
                        defaultValue={isAuth?.authState.user?.position_work}
                        placeholder='Должность'
                        {...register('position_work', {required: true})}
                    />
                    {errors.position_work && <p className="wrong-message">Это поле обязательно</p>}
                </label>
                <label className='account-label'>
                    Номер телефона (+7..)
                    <input
                        type="text"
                        className='regInput'
                        defaultValue={isAuth?.authState.user?.number_phone == null ? '+7' : `+${isAuth?.authState.user?.number_phone}`}
                        placeholder='Ваш номер телефона'
                        {...register('number_phone', {
                            required: true,
                            validate: {validatePhone: (v) => /\+7\d{3}\d{3}\d{2}\d{2}/.test(v) || 'Введите номер телефона, начиная с +7'}
                        })}
                    />
                    {errors.number_phone && <p className="wrong-message">{checkErrorText('number_phone', errors)}</p>}
                    {errors.number_phone && <p className="wrong-message">Это поле обязательно</p>}
                </label>
                <label className='account-label'>
                    Ваш телеграм (https://t.me/...)
                    <input
                        type="text"
                        className='regInput'
                        defaultValue={isAuth?.authState.user?.telegram_link}
                        placeholder='Ваш телеграм'
                        {...register('telegram_link', {required: true})}
                    />
                    {errors.telegram_link && <p className='wrong-message'>Введите ссылку на телеграм</p>}
                </label>
                <label className='account-label'>
                    Расскажите о себе
                    <input
                        type="text"
                        className='regInput'
                        defaultValue={isAuth?.authState.user?.about_yourself}
                        placeholder='Расскажите о себе'
                        {...register('about_yourself')}
                    />
                </label>
                <label
                    className='account-label'
                    {...register('email_subscription', {required: true})}
                >
                    Подписка на "Обзор.НЦПТИ"
                <span>
                    <input
                        type="radio"
                        className='account-label__radio'
                        value='true'
                        {...register('email_subscription')}
                    />
                        <label className='account-label__radio-label'>Включена</label>
                        </span>
                <span>
                    <input
                        type="radio"
                        className='account-label__radio'
                        value='false'
                        {...register('email_subscription')}/>
                        <label className='account-label__radio-label'>Выключена</label>
                        </span>
            </label>

            <button className='button form-btn'>Изменить</button>
        </form>
</Box>
)
    ;
};

export default AccountModal;