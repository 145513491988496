import React from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../constants/constants";
import {formAdapter} from "../UpdateForum/utils";
import {errorToast, successToast} from "../../../../../../shared/toastify";

export interface ICreateForum {
    name: string,
    description: string,
    supplement: string,
    forum_location: string,
    forum_visit_format:{
        id: number,
        name: string
    },
    start_date_training: Date,
    end_date_training: Date,
    is_active: boolean,
    image: string,
    forum_visit_format_offline: boolean;
    forum_visit_format_online: boolean;
}

interface Props{
    close: () => void
}

const CreateNewForum = ({close}: Props) => {
    const {register,
    handleSubmit,
    formState: {errors},
    setError} = useForm<ICreateForum>()

    const handleChange = (event: {target: {files: any};}) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<ICreateForum> = async(data: any) => {
        try{
            const formAdapted: any = formAdapter(data);
            const formData = new FormData();
            console.log(formAdapted);

            for (let key in formAdapted) {
                if (key == "image") {
                    if (!!formAdapted[key][0]) {
                        formData.append(key, formAdapted[key][0]);
                    }
                }

                if (!Array.isArray(formAdapted[key]) && key !== "image") {
                    formData.append(key, data[key]);
                }
                if (Array.isArray(formAdapted[key])) {
                    formAdapted[key].forEach((el: number) =>
                        formData.append(key, String(el))
                    );
                }
            }

            const res = await fetch(`${baseUrl}forum`, {
                method: 'POST',
                body: formData
            });
            if(res.status === 200){
                successToast('Мероприятие успешно создано. Обновите страницу');
                close()
            } else if(res.status === 401){
                setError('root', {type: 'custom', message:''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <div>
            <form
                className="regForm"
                onSubmit={handleSubmit(submit)}
            >
                <input
                    type="text"
                    className="regInput"
                    placeholder='Название мероприятия'
                    {...register('name', {required: true})}
                />
                {errors.name && <p className='wrong-message'>Это поле обязательно</p>}
                <textarea
                    className="regInput"
                    placeholder='Описание мероприятия'
                    {...register('description', {required: true})}
                />
                {errors.description && <p className='wrong-message'>Это поле обязательно</p>}
                <textarea
                    className="regInput"
                    placeholder='Дополнение к описанию'
                    {...register('supplement')}
                />
                <input
                    type="text"
                    className="regInput"
                    placeholder='Место проведения мероприятия'
                    {...register('forum_location', {required: true})}
                />
                {errors.forum_location && <p className='wrong-message'>Это поле обязательно</p>}

                <fieldset
                    className='account-label'

                >
                    Формат мероприятия
                    <span>
                    <input
                        type="checkbox"
                        className='regInput__checkbox-galka'
                        value='1'
                        {...register('forum_visit_format_online')}
                    />
                        <label className='account-label__radio__label'>Онлайн</label>
                        </span>
                    <span>
                    <input
                        type="checkbox"
                        className='regInput__checkbox-galka'
                        value='2'
                        {...register('forum_visit_format_offline')}/>
                        <label className='account-label__radio__label'>Очно</label>
                        </span>
                </fieldset>
                {errors.forum_visit_format && <p className='wrong-message'>Это поле обязательно</p>}

                <label className='regLabel'>
                    Дата начала мероприятия
                    <input
                        type="date"
                        className="regInput"
                        {...register('start_date_training', {required: true})}
                    />
                </label>
                {errors.start_date_training && <p className='wrong-message'>Это поле обязательно</p>}
                <label className='regLabel'>
                    Дата окончания мероприятия
                    <input
                        type="date"
                        className="regInput"
                        {...register('end_date_training', {required: true})}
                    />
                </label>
                {errors.end_date_training && <p className='wrong-message'>Это поле обязательно</p>}
                <span className="regInput__file">
                    <p>Изображение мероприятия</p>
                    <input
                        type="file"
                        {...register('image')}
                        accept='image/jpg, image/jpeg,image/webp, image/png'
                    />
                </span>
                <span className='regInput__checkbox'>
                    <input
                        type="checkbox"
                        className='regInput__checkbox-galka'
                        {...register('is_active')}
                    />
                    <label>Сделать активным?</label>
                </span>
                <button className='button form-btn'>Создать</button>
            </form>
        </div>
    );
};

export default CreateNewForum;

// const formData = new FormData();
// const arr_visit_format = ['1', 'Онлайн', '2', 'Очно']
//
// for(let key in data){
//     if(key == 'image'){
//         formData.append(key, data[key][0])
//     } else {formData.append(key, data[key])}
// }
// for(let i = 0; i < arr_visit_format.length; i++){
//     formData.append('arr[i]', arr_visit_format[i])
// }