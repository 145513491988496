
import React, {useEffect, useState} from 'react';
import './forumsRegistrationForm.scss'
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../constants/constants";
import {AccountInterface} from "../../../../User/AccountPage/account";
import {useAuth} from "../../../../../components/authProvider/authProvider";
import {successToast} from "../../../../../shared/toastify";

export function checkErrorText(inputName: string, errors: any) {
    return errors?.[inputName] && errors?.[inputName]?.message;
}

interface Form{
    id: number,
    email: string,
    full_name: string,
    full_name_declination: string,
    age: number,
    number_phone: string,
    region_residence: string,
    place_of_work: string,
    position_work: string,
    travel_certificate: boolean,
    visit_format_id: number,
    date_creation: Date,
    questions: string
}

interface Props {
    id: number,
    close: () => void,
    forum_visit_format: any[]
}

const RegistrationForm = ({id, close, forum_visit_format}: Props) => {
    const isAuth = useAuth()
    const [disabled, setDisabled] = useState(false)

    const{register,
            handleSubmit,
            formState:{errors},
            setError} = useForm<Form>()

    const submit: SubmitHandler<Form> = async(data) => {
        try{
            const res = await fetch(`${baseUrl}blank_forum`, {
                method: 'POST',
                headers:{
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({...data, forum_id: id})
            });
            if (res.status === 200){
                localStorage.setItem('form', 'accepted');
                successToast('Форма успешно отправлена');
                close()
            } else if(res.status === 401){
                setError('root', {type: 'custom', message: ''})

            } else if(res.status === 409){
                setError('root', {type: 'repeatRequest', message: 'Вы отправили повторную заявку'})
            }
        } catch (err){
            console.error(err)
        }
    }

    const [values, setValues] = useState('1')
    console.log(errors.root)

    useEffect(() => {
        if(!forum_visit_format){
            setDisabled(false)
        } else setDisabled(true)
    }, []);

    return (
       <form
                onSubmit={handleSubmit(submit)}
                className='regForm'
                >
                <input
                    type="text"
                    placeholder='ФИО'
                    className='regInput'
                    {...register('full_name',
                        {required: true, value: isAuth?.authState.user?.full_name})}/>
                {errors.full_name && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    placeholder='ФИО в родительном падеже'
                    className='regInput'
                    {...register('full_name_declination',
                        {required: true,
                            value: isAuth?.authState.user?.full_name_declination})}/>
                {errors.full_name_declination && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    placeholder='Ваш возраст'
                    className='regInput'
                    {...register('age',
                        {required: true,
                            value: isAuth?.authState.user?.age})}/>
                {errors.age && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    placeholder='Субъект России'
                    className='regInput'
                    {...register('region_residence',
                        {required: true, })}/>
                {errors.region_residence && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    placeholder='Место работы'
                    className='regInput'
                    {...register('place_of_work',
                        {required: true,
                            value: isAuth?.authState.user?.place_of_work})}/>
                {errors.position_work && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    placeholder='Должность'
                    className='regInput'
                    {...register('position_work',
                        {required: true,
                            value: isAuth?.authState.user?.position_work})}/>
                {errors.place_of_work && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    placeholder='Контактный номер телефона'
                    className='regInput'
                    defaultValue={isAuth?.authState.user?.number_phone == null ? '+7' : `+${isAuth?.authState.user?.number_phone}`}
                    {...register('number_phone',
                        {required: true,
                            validate: {validatePhone: (v) => /\+7\d{3}\d{3}\d{2}\d{2}/.test(v) || 'Введите номер телефона, начиная с +7'}
                        })}
                />
                {errors.number_phone && <p className='wrong-message'>{checkErrorText('number_phone', errors)}</p>}
                {errors.number_phone && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    placeholder='Электронная почта'
                    className='regInput'
                    {...register('email',
                        {required: true,
                        value: isAuth?.authState.user?.email})}/>
                {errors.email && <p className='wrong-message'>Это поле обязательно</p>}

                <label className='regLabel'>
                    Форма участия:
                    <select
                        id="form-of-participation"
                        value={values}
                        // disabled={disabled}
                        {...register('visit_format_id', {required:true})}
                        onChange = {(e) => setValues(e.target.value)}
                    >
                        {forum_visit_format[0]?.name === 'online' ?
                            <option value='1'>Онлайн</option> :
                            forum_visit_format[0]?.name === 'offline' ?
                                <option value='2' >Очно</option> : ''
                        }
                        {forum_visit_format[1]?.name === 'online' ?
                            <option value='1'>Онлайн</option> :
                            forum_visit_format[1]?.name === 'offline' ?
                                <option value='2' >Очно</option> : ''
                        }
                    </select>
                </label>
           <textarea
                    placeholder='Ответы на какие вопросы вы бы хотели получить'
                    className='regInput'
                    {...register('questions')}
                />
                <span className='regInput__checkbox'>
                    <input type="checkbox" className='regInput__checkbox-galka' {...register('travel_certificate')}/>
                    <label htmlFor='comandirovochnoe'>Нужно ли вам подписать командировочное</label>
                </span>
                <button className="button form-btn">Отправить</button>
                {/*{errors.root && <p className='wrong-message'>{checkErrorText('travel_certificate', errors)}</p>}*/}
                {errors.root && <p className='wrong-message'>Вы уже отправляли заявку на мероприятие</p>}
            </form>
    );
};

export default RegistrationForm;