import React, {ReactNode} from 'react';
import {useAuth} from "../authProvider/authProvider";
import {Navigate} from "react-router-dom";

interface IAdminWrapper{
    children: ReactNode
}

export const AdminWrapper: React.FC<IAdminWrapper> = ({children}) => {
    const authData = useAuth()

    if(!authData?.authState.user?.is_admin){
        return <>{<Navigate to='/' replace={true} />}</>
    }
    return <>{children}</>
}