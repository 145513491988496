import React from 'react';
import {CardsInterface} from "./cardsRouter";
import {baseUrl} from "../../../../../constants/constants";

const OneCard = ({id, name, file_url, file_card_url}: CardsInterface) => {
    return (
        <div className="oneCard">
            <a href={`${baseUrl}${file_url}`}>
                <img
                    className='cards__img'
                    src={`${baseUrl}${file_card_url}`}
                    alt="Обложка карточки"
                    loading='lazy'
                />
                <p className='oneCard-p'>{name}</p>
                </a>
        </div>
    );
};

export default OneCard;