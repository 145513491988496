import React, {useState} from 'react';
import {ICategoryVideos, IVideoSettings} from "../VideoSettings";
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../../constants/constants";
import {errorToast, successToast} from "../../../../../../../shared/toastify";

interface INewInfoVideo {
    name: string,
    video_url: string,
    category_id: number
}

interface ICurrentVideo{
    video: IVideoSettings,
    category: ICategoryVideos[],
    close: () => void
}

const UpdateVideo:React.FC<ICurrentVideo> = ({video,category, close}) => {
    const {
        register,
        handleSubmit,
        formState:{errors},
        setError
    } = useForm<INewInfoVideo>({
        defaultValues: {
            name: video.name,
            video_url: video.video_url,
            category_id: video.category_id
        }
    })
    const [values, setValues] = useState('')

    const submit: SubmitHandler<INewInfoVideo> = async(data) => {
        try{
            const res = await fetch(`${baseUrl}gallery_video/${video.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            if(res.status === 200){
                successToast('Информация обновлена, перезагрузите страницу');
                close()
            } else if(res.status === 401){
                setError('root', {type: 'custom', message: ''})
                errorToast('Ошибка 401')
            }
        } catch (err){
            console.log(err)
        }
    }


    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <label className="regLabel">
                Название:
                <textarea
                    className='regInput'
                    {...register('name')}
                />
            </label>
            <label className="regLabel">
                <textarea
                    className='regInput'
                    {...register('video_url')}
                />
            </label>
            <label
                className="regLabel"
            >
                Категория:
                <select
                    id='category-manuals'
                    value={values}
                    {...register('category_id', {required: true})}
                    onChange={(e) => setValues(e.target.value)}
                >
                    {category.map((i) => <option value={i.id}>{i.name}</option>)}
                </select>
            </label>

            <button className='button form-btn'>Обновить</button>
        </form>
    );
};

export default UpdateVideo;