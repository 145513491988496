import React, { useEffect, useState } from "react";
import "./adminPage.scss";
import Modal from "../../components/modal/modal";
import PublishNewsForm, {IPublishNews} from "./components/PublishNews/PublishNewsForm";
import { baseUrl } from "../../constants/constants";
import NewsList from "./components/NewsList/NewsList";
import NewsArchive from "./components/NewsArchive/NewsArchive";
import UpdateBannerForm from "./components/UpdateBanner/UpdateBannerForm";
import {infoToast, successToast} from "../../shared/toastify";

export interface INews {
  id: number;
  name: string;
  description: string;
  source_link: string;
  image_url: string;
  isArchive?: boolean;
}

const AdminPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showBannerModal, setShowBannerModal] = useState(false)
  const [news, setNews] = useState<INews[]>([]);
  const [archiveNews, setArchiveNews] = useState<INews[]>([]);


    async function switchNewsStatus(id: number) {
    try {
      const res = await fetch(`${baseUrl}news/${id}`, {
        method: "DELETE",
      });
        return res.ok;
    } catch (err) {
      return false;
    }
  }

  const deletePublicNews = (id: number) => {
    setNews(news.filter((news) => news.id !== id));
  };

  const deleteArchiveNews = (id: number) => {
    setArchiveNews(archiveNews.filter((archiveNews) => archiveNews.id !== id));
  };

  const updateStatusNews = (cuurentNews: INews) => {
    switchNewsStatus(cuurentNews.id);

    if (cuurentNews.isArchive) {
      setNews([...news, { ...cuurentNews, isArchive: false }]);
      deleteArchiveNews(cuurentNews.id);
        successToast("Новость возвращена")
    } else {
        setArchiveNews([...archiveNews, { ...cuurentNews, isArchive: true }]);
        deletePublicNews(cuurentNews.id);
        successToast("Новость удалена")
    }
  };

  useEffect(() => {
    fetch(`${baseUrl}news?sort_date=true`)
      .then((res) => res.json())
      .then((data) => {
        setNews(data.map((el: INews) => ({ ...el, isArchive: false })));
      })
      .catch((err) => {
        console.log(err);
      });

    fetch(`${baseUrl}news?page_size=1000&is_archive=true`)
      .then((res) => res.json())
      .then((data) => {
        setArchiveNews(data.map((el: INews) => ({ ...el, isArchive: true })));
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const closeBannerModal = () => {
      setShowBannerModal(false)
  }

  return (
    <>
      <h1 className="admin-title__h1">Редактирование секции "Новости"</h1>
      <div className="admin-buttons">
        <p className="admin-title__p">Создать новость:</p>
        <button onClick={() => setShowModal(true)} className="button admin-btn">
          Добавить
        </button>
        {showModal && (
          <Modal active={showModal} title="Описание новости" сlose={closeModal}>
            <PublishNewsForm close={closeModal}/>
          </Modal>
        )}
      </div>

      <div className="admin-buttons">
          <p className="admin-title__p">Картинка-заголовок: </p>
          <button onClick={() => setShowBannerModal(true)} className="button admin-btn">Заменить</button>
      </div>
        {showBannerModal && (
            <Modal
                active={showBannerModal}
                title='Замена баннера'
                сlose={closeBannerModal}>
                <UpdateBannerForm close={closeBannerModal}/>
            </Modal>
        )}

      {/*Список всех новостей*/}
      <h2 className="admin-title__h2">Список опубликованных новостей:</h2>
      <div className="admin-div">
        {news.length > 0 ? news.map((item, index) => (
          <NewsList
              updateStatusNews={updateStatusNews}
              news={item}
              key={index}
          />
        )) : <h2 className='notFound-title'>Опубликованных новостей нет</h2>
        }
      </div>

      {/*Список архивных новостей*/}
      <h2 className="admin-title__h2">Список архивных новостей:</h2>
      <div className="one-table">
            <table className="applications-table">
                <thead className="applications-table__thead">
                    <tr>
                        <th className="applications-table__thead-th">Картинка</th>
                        <th className="applications-table__thead-th">ID</th>
                        <th className="applications-table__thead-th">Название</th>
                        <th className="applications-table__thead-th">Описание</th>
                        <th className="applications-table__thead-th">Действия</th>
                    </tr>
                </thead>
                {archiveNews.map((i) =>
                    <tbody
                        className='applications-table__tbody'
                        key={i.id}
                    >
                        <NewsArchive
                            news={i}
                            updateStatusNews={updateStatusNews}
                        />
                    </tbody>
                )}
            </table>
      </div>
    </>
  );
};

export default AdminPage;
