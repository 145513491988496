import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../../../constants/constants";
import Modal from "../../../../../../components/modal/modal";
import EmployeeAboutForm from "./employeeModal/EmployeeAboutForm";
import { useParams } from "react-router-dom";
import { EmployeeInterface } from "../department";
import '../../../aboutUs.scss'
import ModalEmployee from "../../../../../../components/modalEmployee/ModalEmployee";
export interface IOneEmployee extends EmployeeInterface{
    className?: string
}

const EmployeeCard:React.FC<IOneEmployee> = ({
                      fio,
                      image_url,
                      interests,
                      department_name,
                      achievement,
                      position_departament_name,
                      priority,
                        className
                  }) => {
    const [showModal, setShowModal] = useState(false);
    const {id} = useParams();

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className={className}>
            {/*<div className="employee">*/}
            <img
                src={`${baseUrl}${image_url}`}
                alt="Фото сотрудника"
                className='employee-photo'
            />
            <div className='employee-card__text'>
                <div>
                <h2 className='employee-fio'>{fio}</h2>
                <p className='employee-work__position'>{position_departament_name}</p>
                </div>
                <a
                    onClick={() => setShowModal(true)}
                    className='employee-about__link'
                >
                    Подробнее
                </a>
            </div>

            {showModal && (
                <ModalEmployee
                    active={showModal}
                    close={closeModal}
                >
                    <EmployeeAboutForm
                        id={Number(id)}
                        fio={fio}
                        position_departament_name={position_departament_name}
                        achievement={achievement}
                        interests={interests}
                        department_name={department_name}
                        image_url={image_url}
                        priority={priority}
                    />
                </ModalEmployee>
            )}
        </div>
        // </div>
    );
};

export default EmployeeCard;