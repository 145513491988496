import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {baseUrl} from "../../../../../constants/constants";
import {useParams} from "react-router-dom";
import {oneDepartmentPhoto} from "../constants";
import {IDepartments} from "../../aboutUs";
import EmployeeCard from "./employee/EmployeeCard";
import '../../aboutUs.scss'
import EmployeeListHeader from "./employee/EmployeeListHeader/EmployeeListHeader";

export interface EmployeeInterface {
    id: number,
    fio: string,
    image_url: string,
    interests: string,
    achievement: string,
    department_name: string,
    position_departament_name: string,
    priority: number
}

const Department = () => {
    const [employee, setEmployee] = useState<EmployeeInterface[]>([])
    const [department, setDepartment] = useState<IDepartments>()
    const {id} = useParams()

    useEffect(() => {
        fetch(`${baseUrl}employee?departament_id=${id}`)
            .then((res) => res.json())
            .then((data: EmployeeInterface[]) =>{
                setEmployee(data.filter(employee => employee.id != 13))
            })
            .catch((err)=>{
                console.log(err)
            })
    }, []);

    useEffect(() => {
        fetch(`${baseUrl}departament/${id}`)
            .then((res) => res.json())
            .then((data: IDepartments) =>{
                setDepartment(data)
            })
            .catch((err)=>{
                console.log(err)
            })
    }, []);

    return (
        <div className='department-page'>
            <section className='title-image'>
                <img
                    src={`${baseUrl}${department?.banner_desktop_url}`}
                    alt="Баннер отдела"
                    loading='lazy'
                    className='title-image__img'
                />
            </section>

            <section className='title-image__mobile'>
                <img
                    src={`${baseUrl}${department?.banner_mobile_url}`}
                    alt="Баннер отдела"
                    loading='lazy'
                    className='title-image__img-mobile'
                />
            </section>

            <div className='page'>
                <div className="team-title">
                    <img
                        className="team-title__img"
                        src={baseUrl + department?.image_url}
                        alt="Групповое фото НЦПТИ"
                        loading='lazy'
                    />
                </div>
                <div className='employee-page'>
                    {employee.length && <EmployeeListHeader employees={employee.slice(0, 3)}/>}
                    <div className='employee-cards__section'>
                        {employee && employee.length > 0 ?
                            employee.slice(3).map((item) =>
                                <EmployeeCard key={item.id}{...item} className='employee'/>
                            )
                            :
                            <h2>Сотрудники не найдены</h2>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Department;