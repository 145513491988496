import React, {useEffect, useMemo, useState} from 'react';
import './catalog.scss'
import {Link} from "react-router-dom";
import {baseUrl} from "../../../../../constants/constants";
import OneJournal from "./components/oneJournal";

export interface JournalInterface{
    id: number,
    name: string,
    file_url: string,
    file_card_url: string,
    type_file: string,
    file_extension: string,
    create_date: Date
}

const Catalog = () => {
    const [journals, setJournals] = useState<JournalInterface[]>([])
    const [currentYear, setCurrentYear] = useState('2024')

    useEffect(() => {
        fetch(`${baseUrl}journals?year_publish=${currentYear}&sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setJournals(data)
            })
    }, [currentYear]);

    const dates = useMemo(() => {
        return Array.from({ length: new Date(Date.now()).getFullYear() - new Date(2012, 1, 1).getFullYear() + 1 }).map((el, i) => `20${12 + i}`).reverse()
    }, [])


    return (
        <div className='page'>
            <section className="catalog-title">
                <h1 className="title-h1">Каталог журналов</h1>
            </section>
            <section className="catalog-btns">
                {
                    dates?.map((year) => (
                        <button className={year == currentYear ? 'button catalog-button__active' : 'button catalog-button'} onClick={() => setCurrentYear(year)}>{year}</button>
                    ))
                }

            </section>
            <section className="catalog-journals">
                {journals && journals.length > 0 ?
                journals.map((item) =>
                    <OneJournal key={item.id}{...item}/>
                )
                :
                <h2 className='notFound-title'>Журналы не найдены</h2>
                }
            </section>
        </div>
    );
};

export default Catalog;