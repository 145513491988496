import React from 'react';
import {IBlankForum} from "../../ApplicationsPage";

const BlankForum = ({
    email,
    full_name,
    full_name_declination,
    age,
    number_phone,
    region_residence,
    place_of_work,
    position_work,
    travel_certificate,
    visit_format,
    date_creation,
    forum
                    }: IBlankForum) => {

    const date_blank_forum = new Date(date_creation).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <tr>
              <td className='applications-table__tbody-td'>{forum.name}</td>
              <td className='applications-table__tbody-td'>{email}</td>
              <td className='applications-table__tbody-td'>{full_name}</td>
              <td className='applications-table__tbody-td'>{full_name_declination}</td>
              <td className='applications-table__tbody-td'>{age}</td>
              <td className='applications-table__tbody-td'>{number_phone}</td>
              <td className='applications-table__tbody-td'>{region_residence}</td>
              <td className='applications-table__tbody-td'>{place_of_work}</td>
              <td className='applications-table__tbody-td'>{position_work}</td>
              <td className='applications-table__tbody-td'>{travel_certificate ? 'Да' : 'Нет'}</td>
              <td className='applications-table__tbody-td'>{visit_format.name}</td>
              <td className='applications-table__tbody-td'>{date_blank_forum}</td>
         </tr>
    );
};

export default BlankForum;