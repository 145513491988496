import React, { useState } from "react";
import { INews } from "../../adminPage";
import { baseUrl } from "../../../../constants/constants";

interface INewsArchive {
  news: INews;
  updateStatusNews: (cuurentNews: INews) => void;
}

const NewsArchive: React.FC<INewsArchive> = ({ news, updateStatusNews }) => {
  return (
    <tr>
        <td className="applications-table__tbody-td">
            <img
                src={`${baseUrl}${news.image_url}`}
                alt='Картинка новости'
                className="cards-settings__img"
            />
        </td>
        <td className="applications-table__tbody-td">{news.id}</td>
        <td className="applications-table__tbody-td">{news.name}</td>
        <td className="applications-table__tbody-td">{news.description}</td>
        <td className="applications-table__tbody-td">
            <button
                className="button updateBtn"
                onClick={() => updateStatusNews(news)}
            >
                Вернуть
            </button>
        </td>
    </tr>
  );
};

export default NewsArchive;
