import React, {useState} from 'react';
import {IForum} from "../../ForumsSettingsPage";
import UpdateForum from "../UpdateForum/UpdateForum";
import {baseUrl} from "../../../../../../constants/constants";
import Modal from "../../../../../../components/modal/modal";


interface IForumsList{
    forums_list: IForum,
    deleteForum: (currentForum: IForum) => void
}

const ForumsList = ({forums_list, deleteForum}: IForumsList) => {
    const [showUpdateForumModal, setShowUpdateForumModal] = useState(false)

    const closeModal = () => {
        setShowUpdateForumModal(false)
    }

    const forum_start_date = new Date(forums_list.start_date_training).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    const forum_end_date = new Date(forums_list.end_date_training).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <section className='admin-settings__section'>
            <img
                src={`${baseUrl}${forums_list.image_url}`}
                alt="Картинка-обложка мероприятия"
                className='admin-settings__img'
            />
            <h2 className="admin-settings__h2">Название: {forums_list.name}</h2>
            <p className="admin-settings__p">Описание: {forums_list.description}</p>
            {/*<p className="forum-settings__p">Дополнения: {forums_list.supplement}</p>*/}
            <p className="admin-settings__p">Город: {forums_list.forum_location}</p>
            <p className="admin-settings__p">Дата начала: {forum_start_date}</p>
            <p className="admin-settings__p">Дата окончания: {forum_end_date}</p>
            {/*<p className="forum-settings__p">Статус: {!forums_list.is_active ? 'Неактивен' : 'Активен'}</p>*/}
            <div className="settings-buttons">
            <button
                onClick={() => setShowUpdateForumModal(true)}
                className='button updateBtn'
            >
                Обновить
            </button>
            {showUpdateForumModal &&
            <Modal
                active={showUpdateForumModal}
                title='Обновите информацию'
                сlose={closeModal}
            >
                <UpdateForum
                    forum={forums_list}
                    close={closeModal}/>
            </Modal>
            }
            <button
                onClick={() => {deleteForum(forums_list)}}
                className='button updateBtn'
            >
                Удалить
            </button>
            </div>
        </section>
    );
};

export default ForumsList;