import React, {useEffect, useState} from 'react';
import VideoList from "./VideoList/VideoList";
import CreateNewVideo from "./CreateNewVideo/CreateNewVideo";
import {baseUrl} from "../../../../../../constants/constants";
import Modal from "../../../../../../components/modal/modal";
import CardsList from "../Cards/CardsList/CardsList";
import {Link} from "react-router-dom";
import {successToast} from "../../../../../../shared/toastify";


export interface IVideoSettings{
    id: number,
    name: string,
    video_url: string,
    create_date: Date,
    category_id: number,
    category_name: string
}

export interface ICategoryVideos{
    id: number,
    name: string
}

const VideoSettings = () => {
    const [videos, setVideos] = useState<IVideoSettings[]>([])
    const [categoryVideos, setCategoryVideos] = useState<ICategoryVideos[]>([])
    const [showVideoModal, setShowVideoModal] = useState(false)

    useEffect(() => {
        fetch(`${baseUrl}gallery_video?page_size=100&sort_date=true`)
            .then((res) => res.json())
            .then((data) => {
                setVideos(data)
            })
            .catch((err)=> {
                console.log(err)
            })

        fetch(`${baseUrl}category?type_category=video`)
            .then((res) => res.json())
            .then((data) => {
                setCategoryVideos(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    async function deleteCurrentVideo(id: number){
        try{
            const res = await fetch(`${baseUrl}gallery_video/${id}`, {
                method: 'DELETE'
            });
            successToast("Видео удалено!")
            return res.ok
        } catch (err){
            console.log(err)
        }
    }

    const deleteVideo = (id: number) => {
        setVideos(videos.filter((videos) => videos.id !== id));
    };

    const updateVideoStatus = (currentVideo: IVideoSettings) => {
        deleteCurrentVideo(currentVideo.id);
        setVideos([...videos, { ...currentVideo}]);
        deleteVideo(currentVideo.id);
    };

    const closeModal = () => {
        setShowVideoModal(false)
    }

    return (
        <>
            <section className="admin-section">
                <h1 className="admin-title__h1-media">Редактирование секции "Медиаматериалы"</h1>
                <section className="admin-media__btns-section">
                    <Link to='#'>
                        <button className="button admin-media__btn-active">Видео</button>
                    </Link>
                    <Link to='/admin-panel/media/manuals'>
                        <button className="button admin-media__btn">Методички</button>
                    </Link>
                    <Link to='/admin-panel/media/cards'>
                        <button className="button admin-media__btn">Карточки</button>
                    </Link>
                </section>
                <div className="admin-buttons">
                    <p className="admin-title__p">Добавить видео: </p>
                    <button
                        className="button admin-btn"
                        onClick={() => setShowVideoModal(true)}
                    >
                        Добавить
                    </button>
                    {showVideoModal &&
                        <Modal
                            active={showVideoModal}
                            title='Добавить видео'
                            сlose={closeModal}
                        >
                            <CreateNewVideo
                                close={closeModal}
                                category={categoryVideos}
                            />
                        </Modal>
                    }
                </div>
                <div style={{marginBottom: '2rem'}}>
                <a
                    href="/add-video-tutorial.docx"
                    download
                    className='register-approval__link'
                   >
                    Инструкция по добавлению видео
                </a>
                </div>
                <div className="one-table">
                    <table className="applications-table">
                        <thead className='applications-table__thead'>
                        <tr>
                            <th className="applications-table__thead-th">Видео</th>
                            <th className="applications-table__thead-th">Название</th>
                            <th className="applications-table__thead-th">Категория</th>
                            <th className="applications-table__thead-th">Действия</th>
                        </tr>
                        </thead>
                        {videos.map((i) => (
                            <tbody
                                className="applications-table__tbody"
                                key={i.id}
                            >
                            <VideoList
                                videoList={i}
                                category={categoryVideos}
                                deleteVideo={updateVideoStatus}
                            />
                            </tbody>
                        ))}
                    </table>
                </div>
            </section>
        </>
    );
};

export default VideoSettings;