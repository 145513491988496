import React from 'react';
import {AccountInterface} from "../../account";
import './account_me.scss'
import {useAuth} from "../../../../../components/authProvider/authProvider";

const AccountMe = () => {
    const isAuth = useAuth()

    return (
        <>
            <p className='account-user__data'>ФИО: {isAuth?.authState.user?.full_name}</p>
            <p className='account-user__data'>ФИО в родительном падеже: {isAuth?.authState.user?.full_name_declination}</p>
            <p className='account-user__data'>Почта: {isAuth?.authState.user?.email}</p>
            <p className='account-user__data'>Пол: {isAuth?.authState.user?.male == null ? '' : isAuth?.authState.user?.male ? 'Мужской' : 'Женский'}</p>
            <p className='account-user__data'>Возраст: {isAuth?.authState.user?.age}</p>
            <p className='account-user__data'>Номер телефона: {isAuth?.authState.user?.number_phone == null ? '' : `+${isAuth?.authState.user?.number_phone}`}</p>
            <p className='account-user__data'>Место работы: {isAuth?.authState.user?.place_of_work}</p>
            <p className='account-user__data'>Должность: {isAuth?.authState.user?.position_work}</p>
            <p className='account-user__data'>Telegram: {isAuth?.authState.user?.telegram_link}</p>
            <p className='account-user__data'>О себе: {isAuth?.authState.user?.about_yourself}</p>
            <p className='account-user__data'>Подписка на "Обзор.НЦПТИ": {isAuth?.authState.user?.email_subscription ? 'Оформлена' : 'Не оформлена'}</p>
        </>
    );
};

export default AccountMe;

