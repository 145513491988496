import React from 'react';

const OrganizationOfTheLecturersActivities = () => {
    return (
        <form className='forum-modal'>
            <h3 className="forum-modal__h">Объем – 16 академических часов.</h3>
            <p className="forum-modal__p">Программа обучения содержит информацию об актуальных угрозах в сфере национальной безопасности и противодействия идеологии терроризма и экстремизму, перечень знаний и умений для организации профилактических мероприятий, инструменты работы с молодежью в очном режиме и в сети Интернет.</p>
        </form>
    );
};

export default OrganizationOfTheLecturersActivities;