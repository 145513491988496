import React, {useEffect, useState} from 'react';
import './education.scss'
import ImageTitle from "../../../assets/title_cards/obuchenie_.webp";
import Gallery, {GalleryInterface} from "./GallerySection/gallery";
import Modal from "../../../components/modal/modal";
import {useAuth} from "../../../components/authProvider/authProvider";
import EducationImg1 from '../../../assets/education/gallery-img-2.jpg'
import EducationModal from "./components/educationModal";
import ModalLogin from "../../../components/modalLogin/modalLogin";
import {baseUrl} from "../../../constants/constants";
import EducationCard1 from '../../../assets/education/education-card/antiterror.png'
import EducationCard2 from '../../../assets/education/education-card/detecting-content.png'
import EducationCard3 from '../../../assets/education/education-card/lektor.png'
import Detection_Of_Illegal_Content from "./components/Modals/Detection_Of_Illegal_Content";
import Organization_of_the_lecturers_activities from "./components/Modals/Organization_of_the_lecturers_activities";
import Countering_the_ideology_of_terrorism from "./components/Modals/Countering_the_ideology_of_terrorism";

export interface IOfflineCourse {
    id: number,
    name: string,
    description: string,
    is_active: boolean,
    is_archive: boolean,
    publish_date: Date
}

interface IBanner{
    id: number;
    name: string,
    file_url: string
}

const Education = () => {
    const [offlineCourses, setOfflineCourses] = useState<IOfflineCourse[]>([])
    const [showModal, setShowModal] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showModalCard1, setShowModalCard1] = useState(false)
    const [showModalCard2, setShowModalCard2] = useState(false)
    const [showModalCard3, setShowModalCard3] = useState(false)
    const isAuth = useAuth()
    const [banner, setBanner] = useState<IBanner[]>([])
    const [mobileBanner, setMobileBanner] = useState<IBanner[]>([])

    useEffect(() => {
        fetch(`${baseUrl}gallery_images?category_id=19`)
            .then((res) => res.json())
            .then((data: IBanner[]) => {
                const banner = data.filter((item) => item.id === 84)
                setBanner(banner)
                console.log(banner)
            })

        fetch(`${baseUrl}gallery_images?category_id=19`)
            .then((res) => res.json())
            .then((data: IBanner[]) => {
                const bannerMobile = data.filter((el) => el.id === 86)
                setMobileBanner(bannerMobile)
            })
    }, []);

    const closeModal = () => {
        setShowModal(false);
        setShowModalCard1(false);
        setShowModalCard2(false);
        setShowModalCard3(false)
    }

    const openPopupWithAuth = () => {
        if (isAuth?.authState.isAuth) {
            setShowModal(true)
        } else {
            setShowLoginModal(true)
        }
    }

    useEffect(() => {
        fetch(`${baseUrl}offline_course`)
            .then((res) => res.json())
            .then((data) => {
                setOfflineCourses(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);


    return (
        <>
            {banner && banner.map((i) => (
            <section className='title-image'>
                <img
                    src={`${baseUrl}${i.file_url}`}
                    alt="Баннер Очное обучение"
                    loading='lazy'
                    className='title-image__img'
                />
            </section>
        ))
        }

            {mobileBanner && mobileBanner.map((e) => (
                <section className='title-image__mobile'>
                    <img
                        src={`${baseUrl}${e.file_url}`}
                        alt="Баннер Очное обучение мобильный"
                        loading='lazy'
                        className='title-image__img-mobile'
                    />
                </section>
            ))}

        <div className='page'>
            <section className='education-section'>
                <div className="education-block">
                    <img src={EducationImg1} alt="Пример обучения" loading='lazy' className='education-img'/>
                    <div className="education-text">
                        <h2 className='education-h'>Мы предоставляем возможность обучения в очном формате на
                            индивидуальных условиях
                            заказчика.</h2>
                        <p className='education-text-p'>Сотрудники НЦПТИ оказывают образовательные услуги в рамках
                            тематики нашего центра, выезжая на обучение в различные регионы Российской Федерации.
                            Программа обучения подбирается в зависимости от целевой аудитории и запроса заказчика. </p>
                    </div>
                </div>
            </section>

            <section className='education-section__DPO'>
                <h1 className="title-h1">Программы ДПО</h1>
                <div
                    className="education-block__DPO"
                >
                    <div className="education-card__DPO">
                        <a onClick={() => setShowModalCard1(true)}>
                        <img
                            src={EducationCard1}
                            alt="Заглушка"
                            className='education-block__DPO-img'
                        />
                        <p className="education-block__DPO-p">Выявление противоправного контента</p>
                        </a>
                        {showModalCard1 &&
                            <Modal
                                active={showModalCard1}
                                title='Выявление противоправного контента и организация профилактики распространения экстремизма в сети Интернет'
                                сlose={closeModal}
                            >
                                <Detection_Of_Illegal_Content/>
                            </Modal>
                        }
                    </div>
                    <div className="education-card__DPO">
                        <a onClick={() => setShowModalCard2(true)}>
                        <img
                            src={EducationCard3}
                            alt="Заглушка"
                            className='education-block__DPO-img'
                        />
                        <p className="education-block__DPO-p">Организация деятельности лектора</p>
                        </a>
                        {showModalCard2 &&
                            <Modal
                                active={showModalCard2}
                                title='Организация деятельности лектора в сфере противодействия идеологии терроризма и профилактики экстремизма'
                                сlose={closeModal}
                            >
                                <Organization_of_the_lecturers_activities/>
                            </Modal>
                        }
                    </div>
                    <div className="education-card__DPO">
                        <a onClick={() => setShowModalCard3(true)}>
                            <img
                                src={EducationCard2}
                                alt="Заглушка"
                                className='education-block__DPO-img'
                            />
                            <p className="education-block__DPO-p">Противодействие идеологии терроризма и экстремизма</p>
                        </a>
                            {showModalCard3 &&
                                <Modal
                                    active={showModalCard3}
                                    title='Противодействие идеологии терроризма и экстремизма в образовательной среде и молодёжной сфере'
                                    сlose={closeModal}
                                >
                                    <Countering_the_ideology_of_terrorism/>
                                </Modal>
                            }
                    </div>
                </div>
                <div className="education-block__DPO-end">
                    <p>По завершению программы участники получают удостоверения о повышении квалификации государственного
                        образца</p>
                    <button
                        style={{marginTop: '2rem'}}
                        className='button education-contats__button'
                        onClick={openPopupWithAuth}
                    >
                        Подать заявку
                    </button>
                </div>
            </section>


            {/*Галерея*/}
            <section className="gallery-section">
                <h1 className='title-h1 gallery-title'>Галерея</h1>
                <div className="gallery">
                    <Gallery/>
                </div>
            </section>

            {/*Кнопка подачи заявки*/}
            <section className="education-section">
                <button
                    className='button education-contats__button'
                    onClick={openPopupWithAuth}
                >
                    Подать заявку
                </button>
                {showModal &&
                    <Modal
                        active={showModal}
                        title={'Оставьте свои данные, наши специалисты свяжутся с Вами для уточнения информации'}
                        сlose={closeModal}
                    >
                        <EducationModal
                            close={closeModal}
                        />
                    </Modal>
                }
                {showLoginModal &&
                    <ModalLogin
                        showLoginModal={showLoginModal}
                        setShowLoginModal={setShowLoginModal}
                    />}
            </section>

            {/*Контакты*/}
            <section className="education-contacts__section">
                <div className="education-block education-contacts__block">
                    <h3 className="education-contacts__block-title">
                        Остались вопросы?<br/> Наши контакты
                    </h3>
                    <p className='education-contacts__block-text'>
                        <a href="mailto:info@ncpti.ru" className='education-contacts__block-link'>info@ncpti.ru</a>
                        <br/>
                        <a href="tel:88632012822" className='education-contacts__block-link'>8 (863) 201-28-22</a>
                    </p>
                    <p className="education-contacts__block-text">
                        <b>Венцель Сергей Владимирович</b> — начальник отдела аналитической и образовательной
                        деятельности НЦПТИ
                    </p>
                    <p className="education-contacts__block-text">
                        <b>Врембяк Александра Аркадьевна</b> — менеджер образовательных проектов НЦПТИ
                    </p>
                </div>
            </section>
        </div>
        </>
    );
};

export default Education;