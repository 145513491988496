import React from 'react';

const CounteringTheIdeologyOfTerrorism = () => {
    return (
        <form className='forum-modal'>
            <h3 className="forum-modal__h">Объем – 16 академических часов.</h3>
            <p className="forum-modal__p">Слушатели курса приобретут базовые знания о формировании структуры противодействия угрозам, планирования и реализации профилактической деятельности, использования различных форматов профилактического воздействия на молодежь.</p>
        </form>
    );
};

export default CounteringTheIdeologyOfTerrorism;