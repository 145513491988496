import React, { ReactNode } from "react";
import { useAuth } from "../authProvider/authProvider";
import { Navigate } from "react-router-dom";

interface IAuthWrapper {
    children: ReactNode;
}

export const AuthWrapper: React.FC<IAuthWrapper> = ({ children }) => {
    const authData = useAuth();

    if (!authData?.authState.isAuth) {
        return <>{<Navigate to="/" replace={true} />}</>;
    }
    return <>{children}</>;
};