import React from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../../../constants/constants";
import {successToast} from "../../../../../../../shared/toastify";

export interface ICreateDepartment{
    name: string,
    image: string,
    banner_desktop: string,
    banner_mobile: string,
    priority: number
}

interface Props{
    close: () => void
}

const CreateNewDepartment = ({close}: Props) => {
    const{
        register,
        handleSubmit,
        formState: {errors},
        setError
    } = useForm<ICreateDepartment>()

    const handleChange = (event: {target: {files: any};}) => {
        console.log(event.target.files)
    }

    const submit: SubmitHandler<ICreateDepartment> = async(data: any) => {
        try{
            const formData = new FormData();

            for(let key in data){
                if(key == 'image' || key == 'banner_desktop' || key == 'banner_mobile'){
                    formData.append(key, data[key][0])
                } else formData.append(key, data[key])
            }
            const res = await fetch(`${baseUrl}departament`, {
                method: 'POST',
                body: formData
            });
            if (res.status === 200){
                successToast('Отдел успешно создано. Обновите страницу');
                close()
            } else if(res.status === 401){
                setError('root', {type:'custom', message:''})
            }
        } catch (err){
            console.log(err)
        }
    }

    return (
        <form
            className='regForm'
            onSubmit={handleSubmit(submit)}
        >
            <input
                type="text"
                className="regInput"
                placeholder='Название отдела'
                {...register('name', {
                    required: true
                })}
            />
            {errors.name && <p className='wrong-message'>Это поле обязательно</p>}

            <span className="regInput__file">
                    <p>Изображение отдела</p>
                    <input
                        type="file"
                        {...register('image', {required: true})}
                        accept='image/jpg, image/jpeg,image/webp, image/png'
                    />
            </span>
            {errors.image && <p className='wrong-message'>Это поле обязательно</p>}

            <span className="regInput__file">
                    <p>Баннер отдела</p>
                    <input
                        type="file"
                        {...register('banner_desktop', {required: true})}
                        accept='image/jpg, image/jpeg,image/webp, image/png'
                    />
            </span>
            {errors.banner_desktop && <p className='wrong-message'>Это поле обязательно</p>}

            <span className="regInput__file">
                    <p>Баннер отдела (мобильный)</p>
                    <input
                        type="file"
                        {...register('banner_mobile', {required: true})}
                        accept='image/jpg, image/jpeg,image/webp, image/png'
                    />
            </span>
            {errors.banner_mobile && <p className='wrong-message'>Это поле обязательно</p>}

            <input
                type="number"
                placeholder='Приоритет'
                className='regInput'
                {...register('priority')}
            />
            <button className="button form-btn">Создать</button>
        </form>
    );
};

export default CreateNewDepartment;