import React, {useState} from 'react';
import {EmployeeInterface} from "../../../department";
import {baseUrl} from "../../../../../../../../constants/constants";
import Modal from "../../../../../../../../components/modal/modal";
import EmployeeAboutForm from "../../employeeModal/EmployeeAboutForm";
import {useParams} from "react-router-dom";
import ModalEmployee from "../../../../../../../../components/modalEmployee/ModalEmployee";

export interface IBigEmployee extends EmployeeInterface {
    className: string
}

const BigEmployeeCard:React.FC<IBigEmployee> = ({
        fio,
        image_url,
        interests,
        department_name,
        achievement,
        position_departament_name,
        priority,
        className
}) => {
    const [showModal, setShowModal] = useState(false);
    const {id} = useParams();

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className={className}>
                <img
                    src={`${baseUrl}${image_url}`}
                    alt="Фото сотрудника"
                    className='employee-photo__big'
                />
                <div className='employee-card__big-text'>
                    <div className="employee-card__big-text-desc">
                        <h2 className='employee-fio__big'>{fio}</h2>
                        <p className='employee-work__position__big'>{position_departament_name}</p>
                    </div>
                        <a
                            onClick={() => setShowModal(true)}
                            className='employee-about__link'
                        >
                            Подробнее
                        </a>
                </div>
                {showModal && (
                    <ModalEmployee
                        active={showModal}
                        close={closeModal}
                    >
                        <EmployeeAboutForm
                            id={Number(id)}
                            fio={fio}
                            position_departament_name={position_departament_name}
                            achievement={achievement}
                            interests={interests}
                            department_name={department_name}
                            image_url={image_url}
                            priority={priority}
                        />
                    </ModalEmployee>
                )}
        </div>
    );
};

export default BigEmployeeCard;