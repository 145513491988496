import React from 'react';
import './MediaSettingsPage.scss'
import {Link} from "react-router-dom";

const MediaSettingPage = () => {
    return (
        <>
            <h1 className="admin-title__h1-media">Редактирование секции "Медиаматериалы"</h1>
            <section className="admin-media__btns-section">
                <Link to='video'>
                    <button className="button admin-media__btn">Видео</button>
                </Link>
                <Link to='manuals'>
                    <button className="button admin-media__btn">Методички</button>
                </Link>
                <Link to='cards'>
                    <button className="button admin-media__btn">Карточки</button>
                </Link>
            </section>
        </>
    );
};

export default MediaSettingPage;