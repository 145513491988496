import React, {useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {baseUrl} from "../../../../../constants/constants";
import {Props} from "../../obzor";
import {useAuth} from "../../../../../components/authProvider/authProvider";
import {successToast} from "../../../../../shared/toastify";


interface ObzorForm{
    email: string,
    full_name: string,
    number_phone: string,
    busyness: string,
    title_article: string,
    theme_article_id: number,
    file_article: string,
    file_data_user: string,
    file_consent_data_processing: string,
    file_anti_plagiarism: string
}
const ObzorRegForm = ({close}:Props) => {
    const{register,
        handleSubmit,
        formState:{errors},
        setError} = useForm<ObzorForm>();
    const isAuth = useAuth()

    const handleChange = (event: { target: { files: any; }; }) => {
        console.log(event.target.files);
    }

        const submit: SubmitHandler<ObzorForm> = async (data: any) => {
        try{
            const formData = new FormData()
            for(let key  in data){
                if(key == 'file_article' || key == 'file_data_user' || key == 'file_consent_data_processing' || key == 'file_anti_plagiarism'){
                    formData.append(key, data[key][0])
                } else { formData.append(key, data[key])}
            }
            const res = await fetch(`${baseUrl}blank_writer`, {
                method: 'POST',
                body: formData
            });
            if(res.status === 200){
                localStorage.setItem('form', 'accepted');
                successToast('Заявка на публикацию отправлена!');
                close()
            } else if (res.status === 401){
                setError('root', {type: 'custom', message: ''})
            }
        } catch (err){
            console.error(err)
        }
    }

    return (
        <div>
            <form
                className='regForm'
                onSubmit={handleSubmit(submit)}
            >
                <input
                    type="text"
                    className='regInput'
                    placeholder='ФИО'
                    {...register('full_name',
                        {required: true,
                                value: isAuth?.authState.user?.full_name
                        })}/>
                {errors.full_name && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    className='regInput'
                    placeholder='Место работы, должность(место учёбы, курс)'
                    {...register('busyness',
                        {required: true,
                        value: isAuth?.authState.user?.place_of_work}
                    )}/>
                {errors.busyness && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    className='regInput'
                    placeholder='Название статьи'
                    {...register('title_article',
                        {required: true})}
                />
                {errors.title_article && <p className='wrong-message'>Это поле обязательно</p>}
                <label className='regLabel'>
                    Выпуск, в который направлена статья:
                    <select
                        id="name-of-journal"
                        {...register('theme_article_id', {required: true})}
                    >
                        <option value="1">"Русофобия как феномен"</option>
                        <option value="2">"Противоправный контент"</option>
                    </select>
                </label>
                <span className="regInput__file">
                    <p>Файл статьи в .doc/.docx</p>
                    <input
                        type="file"
                        {...register('file_article')}
                        accept='application/msword'
                    />
                </span>
                {errors.file_article && <p className='wrong-message'>Загрузите документ в формате .doc/.docx</p>}
                <span className="regInput__file">
                    <p>Файл с данными об авторе (в любом формате)</p>
                    <input
                        type="file"
                        {...register('file_data_user')}
                    />
                </span>
                <span className="regInput__file">
                    <p>Файл с согласием на обработку ПДН в .pdf/.jpg/.jpeg</p>
                    <input
                        type="file"
                        {...register('file_consent_data_processing')}
                        accept='application/pdf, image/jpg, image/jpeg'
                    />
                </span>
                <span className="regInput__file">
                    <p>Файл статьи в .doc/.docx</p>
                    <input
                        type="file"
                        {...register('file_anti_plagiarism')}
                        accept='application/msword'
                    />
                </span>
                <input
                    type="email"
                    className='regInput'
                    placeholder='Контактные данные (Email)'
                    defaultValue={isAuth?.authState.user?.email}
                    {...register('email', {required: true})}
                />
                {errors.email && <p className='wrong-message'>Это поле обязательно</p>}
                <input
                    type="text"
                    className='regInput'
                    placeholder='Контактные данные (Телефон)'
                    defaultValue={isAuth?.authState.user?.number_phone == null ? '+7' : `+${isAuth?.authState.user?.number_phone}`}
                    {...register('number_phone',
                        {required: true})}
                />
                {errors.number_phone && <p className='wrong-message'>Это поле обязательно</p>}
                <button className="button form-btn">Отправить</button>
            </form>
        </div>
    );
};

export default ObzorRegForm;