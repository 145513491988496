import React, {useState} from 'react';
import Home from "./pages/HomePage/home";
import MediaMaterials from "./pages/public/MediaMaterialsPage/mediaMaterials";
import {Routes, Route, Link} from "react-router-dom";
import {Layout} from "./components/Layout";
import Forums from "./pages/public/ForumsPage/forums";
import Video from "./pages/public/MediaMaterialsPage/VideoPage/video";
import Metodichki from "./pages/public/MediaMaterialsPage/MetodichkiPage/metodichki";
import Cards from "./pages/public/MediaMaterialsPage/CardsPage/cards";
import Parallels from "./pages/public/MediaMaterialsPage/VideoPage/video__parallels/parallels";
import Projects from "./pages/public/ProjectsPage/projects";
import OnlineCourses from "./pages/public/OnlineCoursesPage/onlineCourses";
import Education from "./pages/public/ObucheniePage/education";
import Obzor from "./pages/public/ObzorPage/obzor";
import Catalog from "./pages/public/ObzorPage/components/CatalogPage/catalog";
import Login from "./pages/LoginPage/login";
import News from "./pages/public/NewsPage/news";
import Registration from "./pages/LoginPage/RegistrationPage/registration";
import ForgotPassword from "./pages/LoginPage/ForgotPasswordPage/forgotPassword";
import ResetPassword from "./pages/LoginPage/ResetPasswordPage/resetPassword";
import Account from "./pages/User/AccountPage/account";
import AboutUs from "./pages/public/AboutUsPage/aboutUs";
import Department from "./pages/public/AboutUsPage/components/department/department"
import {AuthWrapper} from "./components/authWrapper/authWrapper";
import {AdminWrapper} from "./components/adminWrapper/adminWrapper"
import AdminLayout from "./pages/Admin/adminLayout/adminLayout";
import AdminPage from "./pages/Admin/adminPage";
import AboutUsSettingsPage from "./pages/Admin/Pages/AboutUsSettingsPage/AboutUsSettingsPage";
import ApplicationsPage from "./pages/Admin/Pages/ApplicationsPage/ApplicationsPage";
import CoursesSettingsPage from "./pages/Admin/Pages/CoursesSettingsPage/CoursesSettingsPage";
import UsersSettingPage from "./pages/Admin/Pages/UsersSettingsPage/UsersSettingPage";
import ForumsSettingsPage from "./pages/Admin/Pages/ForumsSettingsPage/ForumsSettingsPage";
import MediaSettingPage from "./pages/Admin/Pages/MediaSettingsPage/MediaSettingPage";
import ManualsSettings from "./pages/Admin/Pages/MediaSettingsPage/components/Manuals/ManualsSettings";
import VideoSettings from "./pages/Admin/Pages/MediaSettingsPage/components/Video/VideoSettings";
import CardsSettings from "./pages/Admin/Pages/MediaSettingsPage/components/Cards/CardsSettings";
import JournalsSettingsPage from "./pages/Admin/Pages/JournalsSettingsPage/JournalsSettingsPage";
import {ToastContainer} from "react-toastify";

function App() {

    const [auth, setAuth] = useState(false)

    return (
    <div className="App">
        <Routes>
                <Route path='/' element={<Layout/>}>
                <Route path='registration' element={<Registration auth={auth} setAuth={setAuth}/>}/>
                <Route path='forgot-password' element={<ForgotPassword/>}/>
                <Route path='reset-password' element={<ResetPassword/>}/>
                    <Route
                        path='account' element={
                        <AuthWrapper>
                            <Account/>
                        </AuthWrapper>
                    }
                        />
                    <Route index element={<Home/>}/>
                    <Route path='news' element={<News/>}/>
                    <Route path='materialy' element={<MediaMaterials/>}/>
                        <Route path='materialy/videomaterialy' element={<Video/>}/>
                        <Route path='materialy/videomaterialy/video-parallels' element={<Parallels/>}/>
                        <Route path='materialy/metodichki' element={<Metodichki/>}/>
                        <Route path='materialy/pamyatki-i-kartochki' element={<Cards/>}/>
                    <Route path='/nashi-proekty' element={<Projects/>}/>
                    <Route path='/forums' element={<Forums/>}/>
                    <Route path='/online-courses' element={<OnlineCourses/>}/>
                    <Route path='/education' element={<Education/>}/>
                    <Route path='/obzor' element={<Obzor/>}/>
                        <Route path='obzor/catalog' element={<Catalog/>}/>
                    <Route path='/about-us' element={<AboutUs/>}/>
                        <Route path='/about-us/department/:id' element={<Department/>}/>
                    <Route path='login' element={<Login/>}/>
                    <Route
                        path='/' element={
                        <AdminWrapper>
                            <AdminLayout/>
                        </AdminWrapper>
                    }>
                        <Route path='/admin-panel' element={<AdminPage/>}/>
                        <Route path='admin-panel/applications' element={<ApplicationsPage/>}/>
                        <Route path='admin-panel/forums' element={<ForumsSettingsPage/>}/>
                        <Route path='admin-panel/courses' element={<CoursesSettingsPage/>}/>
                        <Route path='admin-panel/users' element={<UsersSettingPage/>}/>
                        <Route path='admin-panel/media/' element={<MediaSettingPage/>}/>
                            <Route path='admin-panel/media/manuals' element={<ManualsSettings/>}/>
                            <Route path='admin-panel/media/video' element={<VideoSettings/>}/>
                            <Route path='admin-panel/media/cards' element={<CardsSettings/>}/>
                        <Route path='admin-panel/team' element={<AboutUsSettingsPage/>}/>
                        <Route path='admin-panel/obzor' element={<JournalsSettingsPage/>}/>
                    </Route>
                </Route>
            </Routes>
    </div>
  );
}

export default App;
